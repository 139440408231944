<template>
    <div>
        <div class="actions-tab in-header">
            <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-info"></i>
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-text>
                    <div class="info-button">
                        <span>lorem <i class="icon icon-folder"></i></span>
                    </div>
                    <div class="info-button">
                        <a href="https://www.youtube.com/watch?v=z4H_ozpMSq4" target="_blank">
                            <span>lorem <i class="icon icon-video"></i></span>
                        </a>
                    </div>
                    <div class="info-text">
                    </div>
                </b-dropdown-text>
            </b-dropdown>
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="downloadRep">Приложение 7</b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="table-container">
            <table class="table b-table table-bordered b-table-no-border-collapse">
                <thead class="sticky-thead">
                <tr>
                    <th v-for="(field, index) in tableFields"
                        class="text-center"
                        :key="index"
                        :rowspan="field.rowspan || 1"
                        :colspan="field.colspan || 1"
                        v-html="field.key === 'evaluation' ? getEvaluationLabel() : field.label">
                    </th>
                </tr>
                <tr>
                    <th v-for="year in forecastYears" class="text-center" :key="year">{{ year }}</th>
                </tr>
                </thead>
                <tbody v-for="(row, index) in formApp7" :key="index" class="table-info">
                <tr>
                    <!-- Parent Row -->
                    <td class="toggle-show">
                        <button type="button" class="btn btn-secondary" @click="toggleRow(index)">
                            <i class="icon icon-chevron-circle"></i>
                        </button>
                    </td>
                    <td>{{ row.section }}</td>
                    <td colspan="9">
                        <div>{{ row.name_ru }}</div>
                    </td>
                </tr>
                <template v-if="openRows[index]">
                    <tr v-for="(child, childIndex) in row.child" :key="childIndex" style="background-color: white;">
                        <td v-if="child.parent_code === null" colspan="11">
                            <div>{{ child.subsection + ' ' + child.name_ru }}</div>
                        </td>
                        <template v-else>
                            <td v-if="isFirstRowInGroup(childIndex, row.child)"
                                :rowspan="getRowspanForGroup(child.parent_code, row.child)">
                            </td>
                            <td v-if="isFirstRowInGroup(childIndex, row.child)"
                                :rowspan="getRowspanForGroup(child.parent_code, row.child)">
                                {{ child.parent_code }}
                            </td>
                            <td v-if="isFirstRowInGroup(childIndex, row.child)"
                                :rowspan="getRowspanForGroup(child.parent_code, row.child)">
                                <span v-if="child.section === 1">
                                    {{ child.spf_name_ru }}
                                </span>
                                <span v-else-if="child.section !== 1 && child.description_ru">
                                    {{ child.description_ru }}
                                </span>
                                <span v-else>XXXX</span>
                            </td>
                            <td v-if="child.pkfo_1_code">{{ child.pkfo_1_code }}
                                <span v-if="child.reduction === 1">(сумма вычитается)</span>
                            </td>
                            <td v-else>XXXX</td>
                            <td v-if="child.pkfo_2_code">{{ child.pkfo_2_code }}</td>
                            <td v-else>XXXX</td>
                            <td v-if="child.pkfo_3_code">{{ child.pkfo_3_code }}</td>
                            <td v-else>XXXX</td>
                            <td>
                                <div style="display: inline-flex; align-items: center;">
                                    <span>{{ child.name_ru.replace('*', '') }}</span>
                                    <div v-if="(child.spf === 111 || child.spf === 169) && child.name_ru.endsWith('*')"
                                         class="info-popup ml-2">
                                        <i class="icon icon-danger info-popup-icon"></i>
                                        <div class="info-popup-content" v-if="child.spf === 111">
                                            В сумму по оплате труда включаются затраты по специфике 133 "Возмещение
                                            средней
                                            заработной платы депутатам маслихата по их основному месту работы"
                                        </div>
                                        <div class="info-popup-content" v-if="child.spf === 169">
                                            В сумму по оплате труда включаются затраты по специфике 171 "Возмещение
                                            расходов
                                            по негосударственным займам под государственные гарантии"
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td v-if="actualVersion && child.is_calculation === 'imp_input' && checkImpInput(child, 'value')">
                                <input type="number" v-model="child.value" @input="formatDecimal(child, 'value')"
                                       :class="{ 'disabled-input': access_level === 1 }"
                                       :disabled="access_level === 1"
                                       @blur="blurInput(child, 'value')"
                                       class="form-control no-spinner" style="text-align: right;" step="0.1"/>
                            </td>
                            <td v-else-if="actualVersion && child.is_calculation === 'input'">
                                <input type="number" v-model="child.value" @input="formatDecimal(child, 'value')"
                                       :class="{ 'disabled-input': access_level === 1 }"
                                       :disabled="access_level === 1"
                                       @blur="saveToInputValues(child, 'value')"
                                       class="form-control no-spinner" style="text-align: right;" step="0.1"/>
                            </td>
                            <td v-else class="text-right">{{ $n(toNum(child.value)) }}</td>
                            <td v-if="actualVersion && child.is_calculation === 'imp_input' && checkImpInput(child, 'forecast_1')">
                                <input type="number" v-model="child.forecast_1" @input="formatDecimal(child, 'forecast_1')"
                                       :class="{ 'disabled-input': access_level === 1 }"
                                       :disabled="access_level === 1"
                                       @blur="blurInput(child, 'forecast_1')"
                                       class="form-control no-spinner" style="text-align: right;"/>
                            </td>
                            <td v-else-if="actualVersion &&  child.is_calculation === 'input'">
                                <input type="number" v-model="child.forecast_1" @input="formatDecimal(child, 'forecast_1')"
                                       :class="{ 'disabled-input': access_level === 1 }"
                                       :disabled="access_level === 1"
                                       @blur="saveToInputValues(child, 'forecast_1')"
                                       class="form-control no-spinner" style="text-align: right;"/>
                            </td>
                            <td v-else class="text-right">{{ $n(toNum(child.forecast_1)) }}</td>
                            <td v-if="actualVersion && child.is_calculation === 'imp_input' && checkImpInput(child, 'forecast_2')">
                                <input type="number" v-model="child.forecast_2" @input="formatDecimal(child, 'forecast_2')"
                                       :class="{ 'disabled-input': access_level === 1 }"
                                       :disabled="access_level === 1"
                                       @blur="blurInput(child, 'forecast_2')"
                                       class="form-control no-spinner" style="text-align: right;"/>
                            </td>
                            <td v-else-if="actualVersion && child.is_calculation === 'input'">
                                <input type="number" v-model="child.forecast_2" @input="formatDecimal(child, 'forecast_2')"
                                       :class="{ 'disabled-input': access_level === 1 }"
                                       :disabled="access_level === 1"
                                       @blur="saveToInputValues(child, 'forecast_2')"
                                       class="form-control no-spinner" style="text-align: right;"/>
                            </td>
                            <td v-else class="text-right">{{ $n(toNum(child.forecast_2)) }}</td>
                            <td v-if="actualVersion && child.is_calculation === 'imp_input' && checkImpInput(child, 'forecast_3')">
                                <input type="number" v-model="child.forecast_3" @input="formatDecimal(child, 'forecast_3')"
                                       :class="{ 'disabled-input': access_level === 1 }"
                                       :disabled="access_level === 1"
                                       @blur="blurInput(child, 'forecast_3')"
                                       class="form-control no-spinner" style="text-align: right;"/>
                            </td>
                            <td v-else-if="actualVersion && child.is_calculation === 'input'">
                                <input type="number" v-model="child.forecast_3" @input="formatDecimal(child, 'forecast_3')"
                                       :class="{ 'disabled-input': access_level === 1 }"
                                       :disabled="access_level === 1"
                                       @blur="saveToInputValues(child, 'forecast_3')"
                                       class="form-control no-spinner" style="text-align: right;"/>
                            </td>
                            <td v-else class="text-right">{{ $n(toNum(child.forecast_3)) }}</td>
                        </template>
                    </tr>
                </template>
                </tbody>
            </table>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
    </div>
</template>
<script>

import VueElementLoading from "vue-element-loading";
import moment from "moment/moment";
import {Ax} from "@/utils";

export default {
    name: 'forecast-app7',
    components: {
        'loading': VueElementLoading
    },
    props: {
        year: Number,
        region: String,
        version: String,
        abp: Number,
        dataType: Number,
        region_name: String,
        version_name: String,
        actualVersion: Boolean,
        access_level: Number
    },
    data() {
        return {
            formApp7: [],
            formApp7notChange: [],
            repdate: null,
            loading: false,
            open: false,
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                    class: 'toggle-show',
                    rowspan: 2
                },
                {
                    key: 'section',
                    label: '№',
                    rowspan: 2
                },
                {
                    key: 'spf',
                    label: 'Специфика экономической классификации ЕБК',
                    rowspan: 2
                },
                {
                    key: 'pkfo_1_code',
                    label: 'Строка из прогнозного консолидированного отчета о финансовом положении',
                    rowspan: 2
                },
                {
                    key: 'pkfo_2_code',
                    label: 'Строка из прогнозного консолидированного отчета о результатах финансовой деятельности',
                    rowspan: 2
                },
                {
                    key: 'pkfo_3_code',
                    label: 'Строка из прогнозного консолидированного отчета о движении денег',
                    rowspan: 2
                },
                {
                    key: 'name_ru',
                    label: 'Содержание операции',
                    rowspan: 2
                },
                {
                    key: 'evaluation',
                    label: 'Оценка*, ТЫС.ТЕНГЕ',
                    rowspan: 1
                },
                {
                    key: 'forecast',
                    label: 'Прогноз, ТЫС.ТЕНГЕ',
                    colspan: 4
                }],
            openRows: {},
            originalValues: {},
            inputValues: [],
            inputApp7: []
        }
    },
    async mounted() {
        if (this.actualVersion) {
            await this.LoadApp7('first');
        } else {
            await this.LoadApp7('Yes');
        }
        this.formApp7.forEach((row, index) => {
            this.$set(this.openRows, index, true);
        });
    },
    computed: {
        forecastYears() {
            return [this.year - 1, this.year, this.year + 1, this.year + 2];
        }
    },
    methods: {
        getEvaluationLabel() {
            if (!this.repdate) {
                return `Оценка*, тыс.тенге`;
            }
            return `<span v-b-tooltip.hover title="Оценочные данные\nактуальны на дату:\n${this.formatDate(this.repdate)}"
                style="cursor: pointer;">Оценка*, тыс.тенге</span>`;
        },
        formatDate(date) {
            if (date !== null){
                return new Date(date).toLocaleDateString('ru-RU', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
            }
        },
        checkImpInput(child, param) {
            if (child.code.endsWith('.2') || child.code.endsWith('.4')) {
                const parentCode = child.parent_code;
                const checkRow = child.code.endsWith('.2') ? '.1' : '.3';
                const parentRow = this.formApp7[0].child.find(child => child.parent_code === parentCode && child.code.endsWith(checkRow));
                if (parentRow) {
                    if (!parentRow.validatedColumns) {
                        parentRow.validatedColumns = {};
                    }
                    if (!parentRow.validatedColumns[param]) {
                        if (parentRow[param] === null || parentRow[param] === 0) {
                            return false;
                        }
                        // checkImpInput return true, and user can edit if parentRow = 0 after calculations
                        parentRow.validatedColumns[param] = true;
                    }
                }
            }
            return true;
        },
        formatDecimal(child, field) {
            let value = child[field].toString();
            if (value.includes('.')) {
                value = value.replace(/(\.\d).*/, '$1'); // Keep only one digit after '.'
            }
            child[field] = value;
        },
        saveToInputValues(child, data){
            let parsedValue;
            if (child[data] === undefined || child[data] === null || child[data] === "") {
                parsedValue = null;
            } else {
                parsedValue = parseFloat(child[data]) || 0;
            }
            if (child[data] !== null && child[data] !== 0) {
                const existingEntryIndex = this.inputValues.findIndex(entry => entry.code === child.code);
                if (existingEntryIndex !== -1) {
                    // Update the specific field for an existing entry
                    this.$set(this.inputValues[existingEntryIndex], data, parsedValue);
                } else if (parsedValue !== null) {
                    // Create a new entry if it doesn't exist and the value is not null
                    const newEntry = {
                        code: child.code,
                        value: data === 'value' ? parsedValue : null,
                        forecast_1: data === 'forecast_1' ? parsedValue : null,
                        forecast_2: data === 'forecast_2' ? parsedValue : null,
                        forecast_3: data === 'forecast_3' ? parsedValue : null
                    };
                    this.inputValues.push(newEntry);
                }
            }
        },
        blurInput(child, data) {
            if (child.code.endsWith('.2') || child.code.endsWith('.4')) {
                const parentCode = child.parent_code;
                const checkRow = child.code.endsWith('.2') ? '.1' : '.3';
                const parentRow = this.formApp7[0].child.find(child => child.parent_code === parentCode && child.code.endsWith(checkRow));
                if (parentRow) {
                    if (!this.originalValues.hasOwnProperty(parentCode)) {
                        this.originalValues[parentCode] = {
                            value: parentRow.value,
                            forecast_1: parentRow.forecast_1,
                            forecast_2: parentRow.forecast_2,
                            forecast_3: parentRow.forecast_3
                        };
                    }
                    let parsedValue;
                    if (child[data] === undefined || child[data] === null || child[data] === "") {
                        parsedValue = null;
                    } else {
                        parsedValue = parseFloat(child[data]) || 0;
                    }

                    let childEntry = this.inputValues.find(item => item.code === child.code);
                    let parentEntry = this.inputValues.find(item => item.code === parentRow.code);
                    const originalProperty = this.originalValues[parentCode][data];
                    let totalEn;
                    const parentEn = this.formApp7notChange.find(item => item.code === parentRow.code)[data];
                    const childEn = this.formApp7notChange.find(item => item.code === child.code)[data];
                    if (parentEn !== null && childEn !== null){
                        totalEn = parentEn + childEn;
                    } else {
                        totalEn = null;
                    }
                    const newValue = this.calculateUpdatedValue(originalProperty, child[data], totalEn);
                    if (!parentEntry && parsedValue !== null) {
                        this.inputValues.push({
                            code: parentRow.code,
                            value: data === 'value' ? newValue : null,
                            forecast_1: data === 'forecast_1' ? newValue : null,
                            forecast_2: data === 'forecast_2' ? newValue : null,
                            forecast_3: data === 'forecast_3' ? newValue : null
                        });
                        parentEntry = this.inputValues[this.inputValues.length - 1];
                    } else if (parentEntry) {
                        this.$set(parentEntry, data, newValue);
                    }

                    if (!childEntry && parsedValue !== null) {
                        this.inputValues.push({
                            code: child.code,
                            value: data === 'value' ? parsedValue : null,
                            forecast_1: data === 'forecast_1' ? parsedValue : null,
                            forecast_2: data === 'forecast_2' ? parsedValue : null,
                            forecast_3: data === 'forecast_3' ? parsedValue : null
                        });
                        childEntry = this.inputValues[this.inputValues.length - 1];
                    } else if (childEntry) {
                        this.$set(childEntry, data, parsedValue); // Correctly set data on the childEntry object
                    }

                    // childEntry[data] = child[data];
                    this.$set(parentRow, data, newValue);
                }
            }
        },
        calculateUpdatedValue(parentValue, childValue, total) {
            // console.log(parentValue, childValue, total);
            if (total == null && typeof childValue === 'string'){
                const numericParentValue = this.toNum(parentValue);
                const numericChildValue = this.toNum(childValue) || 0;
                return numericParentValue - numericChildValue;
            } else if (typeof childValue === 'string') {
                const numericChildValue = this.toNum(childValue || '0');
                return total - numericChildValue;
            } else {
                return parentValue;
            }

        },
        isFirstRowInGroup(index, childArray) {
            if (index === 0) return true;
            return childArray[index].parent_code !== childArray[index - 1].parent_code;
        },
        // Method to calculate rowspan for a group
        getRowspanForGroup(parent_code, childArray) {
            if (parent_code === null) {
                return 1;
            } else {
                return childArray.filter(child => child.parent_code === parent_code).length;
            }
        },
        async LoadApp7(answer) {
            this.loading = true;
            try {
                const response = await fetch(`/api-py/get_dict_items_appendix7/` + this.year + '/' + this.region + '/' + this.version + '/' + this.abp + '/' + answer);
                if (response.status === 200) {
                    const data = await response.json();
                    if (answer === 'Yes420' || answer === 'YesBC') {
                        // Update only section 1
                        this.formApp7 = data.result;
                        this.formApp7[0] = { ...this.formApp7[0], ...data.result[0] };
                        this.formApp7notChange = JSON.parse(JSON.stringify(data.result[0].child));
                        this.inputValues = data.input;
                        this.repdate = data.repdate;
                    } else {
                        this.formApp7 = data.result;
                        this.formApp7notChange = JSON.parse(JSON.stringify(data.result[0].child));
                        this.repdate = data.repdate;
                        this.originalValues = {};
                    }
                    if (this.formApp7[0].child.find(child => child.is_calculation === 'imp_input' && (child.value || child.forecast_1 || child.forecast_2 || child.forecast_3))){
                        this.$emit('checkImpInput', true);
                    }
                    if (answer === 'first' || answer === 'Yes'){
                        this.inputValues = data.input;
                    }
                    else {
                        this.inputValues = this.inputValues.map(existingItem => {
                            // Check if the code starts with a value from 1 to 92
                            if (/^(9[0-2]|[1-8]?[0-9])(\.\d+)?$/.test(existingItem.code)) {
                                // Find matching item in the new data input
                                const updatedItem = data.input.find(item => item.code === existingItem.code);
                                return {
                                    code: existingItem.code,
                                    value: updatedItem ? updatedItem.value : null,
                                    forecast_1: updatedItem ? updatedItem.forecast_1 : null,
                                    forecast_2: updatedItem ? updatedItem.forecast_2 : null,
                                    forecast_3: updatedItem ? updatedItem.forecast_3 : null
                                };
                            } else {
                                return existingItem;
                            }
                        });

                        const newItems = data.input.filter(
                            newItem =>
                                /^(9[0-2]|[1-8]?[0-9])(\.\d+)?$/.test(newItem.code) && // Check if code starts with 1 to 92
                                !this.inputValues.some(existingItem => existingItem.code === newItem.code)
                        );

                        this.inputValues = [...this.inputValues, ...newItems];
                    }
                    if (data.checkEmpty === 0 && answer === 'first') {
                        this.$emit('openModal');
                        this.$emit('checkImpInput', false);
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка Приложение 7', error.toString());
            }
            this.loading = false;
        },
        async SaveData() {
            if (Object.keys(this.inputValues).length > 0) {
                this.inputData = [];
                const item = Object.assign({});

                this.$set(item, 'inputValues', this.inputValues);
                this.$set(item, 'cur_year', this.year);
                this.$set(item, 'budget_variant', this.version);
                this.$set(item, 'abp_code', this.abp);
                this.$set(item, 'user_id', this.$store.getters.user_uuid);
                this.$set(item, 'update_date', moment().format('YYYY-MM-DD'));
                this.$set(item, 'code_form', 'PR7');
                this.$set(item, 'region_code', this.region);
                this.$set(item, 'data_type', this.dataType);
                this.$set(item, 'evaluation_date', this.repdate ? this.repdate : null);
                this.isLoad = true;
                this.$emit('updateLoading', true);
                this.inputData.push(item);
                try {
                    const response = await fetch('/api-py/add_data_to_appendix7', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(this.inputData)
                    });
                    const result = await response.json();
                    if (response.status === 200 && result.result === 'success') {
                        this.isSave = false;
                        this.makeToast('success', 'Сообщение', 'Данные сохранены');
                        await this.LoadApp7('first');
                        this.$emit('updateReport');
                    } else {
                        if (result.result === 'error') {
                            throw 'Ошибка сохранения данных.';
                        }
                    }
                } catch (e) {
                    this.makeToast('danger', 'Предупреждение', e.toString());
                } finally {
                    this.isLoad = false;
                    this.$emit('updateLoading', false);
                }
            }

        },
        downloadRep() {
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.isLoad = true;
                this.curParams = { year: this.year, region: this.region, version: this.version, abp: this.abp, data_type: this.dataType, reg_name: this.region_name, ver_name: this.version_name, actVer: this.actualVersion };
                Ax(
                    {
                        url: '/api-py/forecast_reporting_download_app7/' + JSON.stringify(this.curParams),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Приложение 7.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
            } finally {
                this.isLoad = false
            }
        },
        toggleRow(index) {
            this.$set(this.openRows, index, !this.openRows[index]);
        },
        toNum(value, fix = 1) {
            if (typeof (value) === 'string') {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof (value) === 'number') {
                return parseFloat(value.toFixed(fix));
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if (typeof (value) === 'undefined') {
                return 0;
            }
            if (isNaN(value)) {
                return 0;
            }
            if (isFinite(value)) {
                return 0;
            }
            if (value === null) {
                return 0;
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
    }
}
</script>
<style>
.sticky-thead {
    position: sticky;
    top: 40px;
    background-color: #fff;
    height: 92px;
    z-index: 10;
}

.sticky-thead tr:nth-child(1) th {
    height: 46px;
}

/* The height of the first row */
.sticky-thead tr:nth-child(2) th {
    top: 46px;
}
.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.disabled-input {
    background-color: #e9ecef !important;
}

</style>