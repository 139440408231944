<template>
    <div>
        <div class="actions-tab in-header">
            <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-info"></i>
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-text>
                    <div class="info-button">
                        <span>lorem <i class="icon icon-folder"></i></span>
                    </div>
                    <div class="info-button">
                        <a href="https://www.youtube.com/watch?v=z4H_ozpMSq4" target="_blank">
                            <span>lorem <i class="icon icon-video"></i></span>
                        </a>
                    </div>
                    <div class="info-text">
                    </div>
                </b-dropdown-text>
            </b-dropdown>
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="downloadRep">Приложение 10</b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="table-container">
            <table class="table b-table table-bordered b-table-no-border-collapse">
                <thead>
                <tr class="sticky-thead1">
                    <th v-for="(field, index) in tableFields" class="text-center" :key="index">
                        <template v-if="field.key === 'action'">
                            <button type="button" class="btn btn-secondary">
                                <i class="icon icon-chevron-circle"></i>
                            </button>
                        </template>
                        <template v-else>
                            {{ field.label }}
                        </template>
                    </th>
                </tr>
                <tr class="sticky-thead2">
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th v-for="number in 10" :key="number" class="text-center">{{ number }}</th>
                </tr>
                </thead>
                <tbody v-for="(row, index) in formApp10" :key="index" class="table-info">
                <tr class="text-right">
                    <!-- Parent Row -->
                    <td class="toggle-show">
                        <button type="button" class="btn btn-secondary" @click="toggleRow(index)">
                            <i class="icon icon-chevron-circle"></i>
                        </button>
                    </td>
                    <td>{{ row.parent_code }}</td>
                    <td>{{ row.name_ru }}</td>
                    <td v-if="row.column2">{{ $n(row.column2) }}</td>
                    <td v-else></td>
                    <td v-if="row.column3">{{ $n(row.column3) }}</td>
                    <td v-else></td>
                    <td v-if="row.column4">{{ $n(row.column4) }}</td>
                    <td v-else></td>
                    <td v-if="row.column5">{{ $n(row.column5) }}</td>
                    <td v-else></td>
                    <td>{{ row.rate }}</td>
                    <td v-if="row.column7">{{ $n(row.column7) }}</td>
                    <td v-else></td>
                    <td v-if="row.column8">{{ $n(row.column8) }}</td>
                    <td v-else></td>
                    <td v-if="row.column9">{{ $n(row.column9) }}</td>
                    <td v-else></td>
                    <td v-if="row.column10">{{ $n(row.column10) }}</td>
                    <td v-else></td>
                </tr>
                <template v-if="openRows[index]">
                    <tr v-for="(child, childIndex) in row.child" :key="childIndex" class="text-right"
                        :style="{ backgroundColor: (child.code === '1.1' || child.code.endsWith('.0')) ? '#e8f4fa' : 'white' }">
                        <td></td>
                        <td v-if="child.code === '1.1'">{{ '1.1' }}</td>
                        <td v-else-if="child.parent_code.startsWith('1') && child.code.endsWith('.0')">{{child.parent_code}}</td>
                        <td v-else-if="/^[2-4]/.test(child.parent_code)">{{child.parent_code}}</td>
                        <td v-else></td>
                        <td>{{ child.name_ru }}</td>
                        <td v-if="actualVersion && !child.code.endsWith('.0') && child.code !== '1.1'">
                            <input type="number" v-model="child.column2"
                                   @blur="blurInput(child, 'column2')"
                                   :class="{ 'disabled-input': access_level === 1 }"
                                   :disabled="access_level === 1"
                                   class="form-control no-spinner" style="text-align: right;" step="0.1"/>
                        </td>
                        <td v-else>{{$n(toNum(child.column2))}}</td>
                        <td v-if="actualVersion && !child.code.endsWith('.0') && child.code !== '1.1'">
                            <input type="number" v-model="child.column3"
                                   @blur="blurInput(child, 'column3')"
                                   :class="{ 'disabled-input': access_level === 1 }"
                                   :disabled="access_level === 1"
                                   class="form-control no-spinner" style="text-align: right;" step="0.1"/>
                        </td>
                        <td v-else>{{$n(toNum(child.column3))}}</td>
                        <td v-if="actualVersion && !child.code.endsWith('.0') && child.code !== '1.1'">
                            <input type="number" v-model="child.column4"
                                   @blur="blurInput(child, 'column4')"
                                   :class="{ 'disabled-input': access_level === 1 }"
                                   :disabled="access_level === 1"
                                   class="form-control no-spinner" style="text-align: right;" step="0.1"/>
                        </td>
                        <td v-else>{{$n(toNum(child.column4))}}</td>
                        <td v-if="child.column5">{{$n(child.column5)}}</td>
                        <td v-else></td>
                        <td v-if="child.rate">{{ child.rate + '%'}}</td>
                        <td v-else></td>
                        <td v-if="child.column7">{{$n(child.column7)}}</td>
                        <td v-else></td>
                        <td v-if="actualVersion && !child.code.endsWith('.0') && child.code !== '1.1'">
                            <input type="number" v-model="child.column8"
                                   @blur="blurInput(child, 'column8')"
                                   :class="{ 'disabled-input': access_level === 1 }"
                                   :disabled="access_level === 1"
                                   class="form-control no-spinner" style="text-align: right;" step="0.1"/>
                        </td>
                        <td v-else>{{$n(toNum(child.column8))}}</td>
                        <td v-if="child.column9">{{$n(child.column9)}}</td>
                        <td v-else></td>
                        <td v-if="child.column10">{{$n(child.column10)}}</td>
                        <td v-else></td>
                    </tr>
                </template>
                </tbody>
                <tfoot>
                <tr class="text-right font-weight-bold" style="font-size: 15px">
                    <td></td>
                    <td></td>
                    <td><strong>Итого</strong></td>
                    <td>{{ $n(totalRow.column2) || '' }}</td>
                    <td>{{ $n(totalRow.column3) || '' }}</td>
                    <td>{{ $n(totalRow.column4) || '' }}</td>
                    <td>{{ $n(totalRow.column5) || '' }}</td>
                    <td></td>
                    <td>{{ $n(totalRow.column7) || '' }}</td>
                    <td>{{ $n(totalRow.column8) || '' }}</td>
                    <td>{{ $n(totalRow.column9) || '' }}</td>
                    <td>{{ $n(totalRow.column10) || '' }}</td>
                </tr>
                </tfoot>
            </table>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
    </div>
</template>
<script>

import VueElementLoading from "vue-element-loading";
import moment from "moment/moment";
import {Ax} from "@/utils";

export default {
    name: 'forecast-app10',
    components: {
        'loading': VueElementLoading
    },
    props: {
        year: Number,
        region: String,
        version: String,
        abp: Number,
        dataType: Number,
        region_name: String,
        version_name: String,
        radio_year: Number,
        actualVersion: Boolean,
        access_level: Number
    },
    data() {
        return {
            formApp10: [],
            loading: false,
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                    class: 'toggle-show'
                },
                {
                    key: 'num',
                    label: '№'
                },
                {
                    key: 'column1',
                    label: 'Наименование долгосрочных активов'
                },
                {
                    key: 'column2',
                    label: 'Сальдо на начало оценочного/прогнозного года по первоначальной стоимости'
                },
                {
                    key: 'column3',
                    label: 'Сумма поступления на оценочный/прогнозный год'
                },
                {
                    key: 'column4',
                    label: 'Сумма выбытия долгосрочных активов на оценочный/прогнозный год'
                },
                {
                    key: 'column5',
                    label: 'Сальдо на конец оценочного/прогнозного года по первоначальной стоимости (графы 2, 3, 4)'
                },
                {
                    key: 'column6',
                    label: 'Годовая норма износа в %'
                },
                {
                    key: 'column7',
                    label: 'Сумма начисленной амортизации на оценочный/прогнозный год, тенге (графы 5, 6)'
                },
                {
                    key: 'column8',
                    label: 'Сумма накопленной амортизации на начало оценочного/прогнозного года'
                },
                {
                    key: 'column9',
                    label: 'Сумма накопленной амортизации на конец оценочного/прогнозного года (графы 7, 8)'
                },
                {
                    key: 'column10',
                    label: 'Сальдо на конец оценочного/прогнозного года по балансовой стоимости (графы 5, 9)'
                }],
            openRows: {},
            originalValues: {},
            originalCancel: [],
            inputValues: [],
            inputApp7: [],
            totalRow: {
                column2: 0,
                column3: 0,
                column4: 0,
                column5: 0,
                rate: null,
                column7: 0,
                column8: 0,
                column9: 0,
                column10: 0,
            },
        }
    },
    async mounted() {
        await this.LoadApp10(this.radio_year);
        this.formApp10.forEach((row, index) => {
            this.$set(this.openRows, index, true);
        });
    },
    created() {
        this.$on('activateEditMode', this.setEditMode);
    },
    computed: {
        forecastYears() {
            return [this.year - 1, this.year, this.year + 1, this.year + 2];
        }
    },
    watch: {
        radio_year() {
            this.inputValues = [];
        }
    },
    methods: {
        formatDecimal(child, field) {
            let value = child[field].toString();
            if (value.includes('.')) {
                value = value.replace(/(\.\d).*/, '$1'); // Keep only one digit after '.'
            }
            child[field] = value;
        },
        blurInput(child, data) {
            let parsedValue;
            if (child[data] === undefined || child[data] === null || child[data] === "") {
                parsedValue = null;
            } else {
                parsedValue = parseFloat(child[data]) || 0;
            }
            if (child[data] !== null && child[data] !== 0) {
                const existingEntryIndex = this.inputValues.findIndex(entry => entry.code === child.code);
                if (existingEntryIndex !== -1) {
                    // Update the specific field for an existing entry
                    this.$set(this.inputValues[existingEntryIndex], data, parsedValue);
                } else if (parsedValue !== null) {
                    // Create a new entry if it doesn't exist and the value is not null
                    const newEntry = {
                        code: child.code,
                        column2: data === 'column2' ? parsedValue : null,
                        column3: data === 'column3' ? parsedValue : null,
                        column4: data === 'column4' ? parsedValue : null,
                        column8: data === 'column8' ? parsedValue : null
                    };
                    this.inputValues.push(newEntry);
                }
            }

            const col2 = parseFloat(child.column2) || 0;
            const col3 = parseFloat(child.column3) || 0;
            const col4 = parseFloat(child.column4) || 0;
            const col8 = parseFloat(child.column8) || 0;

            const result5 = col2 + col3 - col4;
            this.$set(child, 'column5', this.toNum(result5));

            const result7 = result5 * child.rate/100;
            this.$set(child, 'column7', this.toNum(result7));

            const result9 = result7 + col8;
            this.$set(child, 'column9', this.toNum(result9));

            const result10 = result5 - result9;
            this.$set(child, 'column10', this.toNum(result10));

            const parentCode = child.parent_code;
            const index = parseInt(parentCode[0], 10) - 1;
            let mainRow;
            let sumRows;
            if (index > 0){
                mainRow = this.formApp10[index];
                sumRows = this.formApp10[index].child;
            } else {
                mainRow = this.formApp10[index].child.find(child => child.parent_code === parentCode && (child.code.endsWith('.0') || child.code === '1.1'));
                sumRows = this.formApp10[index].child.filter(child => child.parent_code === parentCode && (!child.code.endsWith('.0') && child.code !== '1.1'));
            }
            if (mainRow && sumRows) {
                const res = sumRows.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                this.$set(mainRow, data, this.toNum(res));

                const column5Sum = sumRows.reduce((acc, row) => acc + (parseFloat(row.column5) || 0), 0);
                this.$set(mainRow, 'column5', this.toNum(column5Sum));

                const column7Sum = sumRows.reduce((acc, row) => acc + (parseFloat(row.column7) || 0), 0);
                this.$set(mainRow, 'column7', this.toNum(column7Sum));

                const column9Sum = sumRows.reduce((acc, row) => acc + (parseFloat(row.column9) || 0), 0);
                this.$set(mainRow, 'column9', this.toNum(column9Sum));

                const column10Sum = sumRows.reduce((acc, row) => acc + (parseFloat(row.column10) || 0), 0);
                this.$set(mainRow, 'column10', this.toNum(column10Sum));
            }
            const mainArray1 = this.formApp10[0];
            const sumArray1 = this.formApp10[0].child.filter(child => child.code.endsWith('.0') || child.code === '1.1' || child.code === '1.2.1');
            if (mainArray1 && sumArray1) {
                const res1 = sumArray1.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                this.$set(mainArray1, data, this.toNum(res1));

                const column5Sum1 = sumArray1.reduce((acc, row) => acc + (parseFloat(row.column5) || 0), 0);
                this.$set(mainArray1, 'column5', this.toNum(column5Sum1));

                const column7Sum1 = sumArray1.reduce((acc, row) => acc + (parseFloat(row.column7) || 0), 0);
                this.$set(mainArray1, 'column7', this.toNum(column7Sum1));

                const column9Sum1 = sumArray1.reduce((acc, row) => acc + (parseFloat(row.column9) || 0), 0);
                this.$set(mainArray1, 'column9', this.toNum(column9Sum1));

                const column10Sum1 = sumArray1.reduce((acc, row) => acc + (parseFloat(row.column10) || 0), 0);
                this.$set(mainArray1, 'column10', this.toNum(column10Sum1));
            }
            const totalArray = this.formApp10;
            const totalSum = totalArray.reduce((acc, item) => acc + (parseFloat(item[data]) || 0), 0);
            this.$set(this.totalRow, data, this.toNum(totalSum));

            const total5 = totalArray.reduce((acc, item) => acc + (parseFloat(item.column5) || 0), 0);
            this.$set(this.totalRow, 'column5', this.toNum(total5));
            const total7 = totalArray.reduce((acc, item) => acc + (parseFloat(item.column7) || 0), 0);
            this.$set(this.totalRow, 'column7', this.toNum(total7));
            const total9 = totalArray.reduce((acc, item) => acc + (parseFloat(item.column9) || 0), 0);
            this.$set(this.totalRow, 'column9', this.toNum(total9));
            const total10 = totalArray.reduce((acc, item) => acc + (parseFloat(item.column10) || 0), 0);
            this.$set(this.totalRow, 'column10', this.toNum(total10));

            },
        async LoadApp10(radio_year) {
            this.totalRow = {column2: 0, column3: 0, column4: 0, column5: 0, rate: null, column7: 0, column8: 0, column9: 0, column10: 0};
            this.loading = true;
            try {
                const response = await fetch(`/api-py/get_dict_items_appendix10/` + radio_year + '/' + this.version + '/' + this.abp + '/' + this.year);
                if (response.status === 200) {
                    const data = await response.json();
                    this.formApp10 = data.result;
                    this.formApp10.forEach(row => {
                        row.child.forEach(child => {
                            if ('column2' in child) {
                                this.blurInput(child, 'column2');
                            }
                            if ('column3' in child) {
                                this.blurInput(child, 'column3');
                            }
                            if ('column4' in child) {
                                this.blurInput(child, 'column4');
                            }
                            if ('column8' in child) {
                                this.blurInput(child, 'column8');
                            }
                        });
                    });
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка Приложение 7', error.toString());
            }
            this.loading = false;
        },
        async SaveData() {
            if (Object.keys(this.inputValues).length > 0) {
                this.inputData = [];
                const item = Object.assign({});

                this.$set(item, 'inputValues', this.inputValues);
                this.$set(item, 'cur_year', this.year);
                this.$set(item, 'budget_variant', this.version);
                this.$set(item, 'abp_code', this.abp);
                this.$set(item, 'user_id', this.$store.getters.user_uuid);
                this.$set(item, 'update_date', moment().format('YYYY-MM-DD'));
                this.$set(item, 'code_form', 'PR10');
                this.$set(item, 'region_code', this.region);
                this.$set(item, 'data_type', this.dataType);
                this.$set(item, 'radio_year', this.radio_year)
                this.isLoad = true;
                this.inputData.push(item);
                try {
                    const response = await fetch('/api-py/add_data_to_appendix10', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(this.inputData)
                    });
                    const result = await response.json();
                    if (response.status === 200 && result.result === 'success') {
                        this.isSave = false;
                        this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    } else {
                        if (result.result === 'error') {
                            throw 'Ошибка сохранения данных.';
                        }
                    }
                } catch (e) {
                    this.makeToast('danger', 'Предупреждение', e.toString());
                } finally {
                    this.isLoad = false;
                }
            }

        },
        downloadRep() {
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.isLoad = true;
                this.curParams = { year: this.year, region: this.region, version: this.version, abp: this.abp, data_type: this.dataType, reg_name: this.region_name, ver_name: this.version_name, radio_year: this.radio_year };
                Ax(
                    {
                        url: '/api-py/forecast_reporting_download_app10/' + JSON.stringify(this.curParams),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Приложение 10.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
            } finally {
                this.isLoad = false
            }
        },
        toggleRow(index) {
            this.$set(this.openRows, index, !this.openRows[index]);
        },
        toNum(value, fix = 1) {
            if (typeof (value) === 'string') {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof (value) === 'number') {
                return parseFloat(value.toFixed(fix));
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if (typeof (value) === 'undefined') {
                return 0;
            }
            if (isNaN(value)) {
                return 0;
            }
            if (isFinite(value)) {
                return 0;
            }
            if (value === null) {
                return 0;
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
    }
}
</script>
<style>
.sticky-thead1 {
    position: sticky;
    top: 40px;
    height: 120px;
    background-color: #fff;
    z-index: 10;
}

.sticky-thead2 {
    position: sticky;
    top: 160px;
    background-color: #fff;
    z-index: 10;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.disabled-input {
    background-color: #e9ecef !important;
}

</style>