<template>
    <div>
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i>
                        Фильтр
                        </span><span class="rc"><i
                        class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>
                        <!--------------- Плановый период ------------------->
                        <div class="filter-block">
                            <b-form-group label="Плановый период">
                                <multiselect
                                    ref="bcPlanYear"
                                    v-model="planPeriod"
                                    :options="planPeriodYears"
                                    placeholder="Выберите год"
                                    @close="handleChange('period')">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Регион ------------------->
                        <div class="filter-block">
                            <b-form-group label="Регион">
                                <multiselect
                                    ref="bcRegion"
                                    v-model="selectedReg"
                                    :options="regionList"
                                    track-by="code"
                                    label="text"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    placeholder="Выберите регион"
                                    @close="handleChange('region')">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Вид данных ------------------->
                        <div class="filter-block">
                            <b-form-group label="Вид данных">
                                <multiselect
                                    ref="bcDataType"
                                    v-model="dataType"
                                    :options="dataTypeList"
                                    track-by="code"
                                    label="text"
                                    placeholder="Выберите вид данных"
                                    @input="loadVariantByDataType">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Версия бюджета ------------------->
                        <div class="filter-block">
                            <b-form-group label="Версия бюджета">
                                <multiselect
                                    ref="bcVariant"
                                    track-by="variant_uuid"
                                    label="name_ru"
                                    v-model="variant"
                                    :options="variantList"
                                    placeholder="Выберите версию бюджета"
                                    @close="getForecastReportingForm">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- АБП ------------------->
                        <div class="filter-block">
                            <b-form-group label="АБП">
                                <multiselect
                                    ref="bcAbp"
                                    v-model="abp"
                                    :options="abpList"
                                    label="text"
                                    placeholder="Выберите АБП"
                                    @close="getForecastReportingForm">
                                </multiselect>
                            </b-form-group>
                        </div>

                    </div>
                    <br>
                    <div v-if="showPril7||showPril10||showPKFO1||showPril8||showPKFO2||showPril9||showPKFO3||showPKFO4"
                         class="filter-actions" @click="handleApply">
                        <b-button variant="primary">
                            Применить
                        </b-button>
                        <b-button variant="secondary" @click="resetButton">
                            Сбросить
                        </b-button>
                    </div>
                </b-dropdown>
                <span v-if="showPril7" class="filter-title" style="margin-left: 15px">Приложение 7</span>
                <span v-if="showPril10" class="filter-title" style="margin-left: 15px">Приложение 10</span>
                <span v-if="showPKFO1" class="filter-title" style="margin-left: 15px">ПКФО-1</span>
                <span v-if="showPril8" class="filter-title" style="margin-left: 15px">Приложение 8</span>
                <span v-if="showPKFO2" class="filter-title" style="margin-left: 15px">ПКФО-2</span>
                <span v-if="showPril9" class="filter-title" style="margin-left: 15px">Приложение 9</span>
                <span v-if="showPKFO3" class="filter-title" style="margin-left: 15px">ПКФО-3</span>
                <span v-if="showPKFO4" class="filter-title" style="margin-left: 15px">ПКФО-4</span>
                <b-form-radio-group
                    style="margin-left: 15px"
                    v-if="showPril10"
                    id="radio-group-2"
                    v-model="year10"
                    :options="formattedYears"
                    @input="loadData10">
                </b-form-radio-group>
            </div>
            <div v-if="showPril7" class="filter-actions">
                <b-button variant="light" @click="returnToList('PR7')"><i
                    class="icon icon-keyboard icon-rotate-180"></i>
                    Назад к списку
                </b-button>
                <b-button v-if="actualVersion" :disabled="access_level === 1 || isLoad7" variant="success"
                          @click="saveData7">Сохранить
                </b-button>
                <b-button v-if="actualVersion" :disabled="access_level === 1" variant="secondary"
                          @click="showModalUpdate('Yes420')">Обновить из ф. 4-20
                </b-button>
                <b-button v-if="actualVersion" :disabled="access_level === 1" variant="secondary"
                          @click="showModalUpdate('YesBC')">Обновить из БЗ
                </b-button>
            </div>
            <div v-if="showPril10" class="filter-actions">
                <b-button variant="light" @click="returnToList('PR10')"><i
                    class="icon icon-keyboard icon-rotate-180"></i>
                    Назад к списку
                </b-button>
                <b-button v-if="actualVersion" :disabled="access_level === 1"
                          variant="success" @click="saveData10">Сохранить
                </b-button>
            </div>
            <div v-if="showPKFO1" class="filter-actions">
                <b-button variant="light" @click="returnToList('pkfo1')"><i
                    class="icon icon-keyboard icon-rotate-180"></i>
                    Назад к списку
                </b-button>
                <b-button v-if="actualVersion" :disabled="access_level === 1" variant="success" @click="savePKFO1">
                    Сохранить
                </b-button>
            </div>
            <div v-if="showPril8" class="filter-actions">
                <b-button variant="light" @click="returnToList('PR8')"><i
                    class="icon icon-keyboard icon-rotate-180"></i>
                    Назад к списку
                </b-button>
                <b-button v-if="actualVersion" :disabled="access_level === 1" variant="success" @click="saveData8">
                    Сохранить
                </b-button>
            </div>
            <div v-if="showPKFO2" class="filter-actions">
                <b-button variant="light" @click="returnToList('pkfo2')"><i
                    class="icon icon-keyboard icon-rotate-180"></i>
                    Назад к списку
                </b-button>
            </div>
            <div v-if="showPril9" class="filter-actions">
                <b-button variant="light" @click="returnToList('PR9')"><i
                    class="icon icon-keyboard icon-rotate-180"></i>
                    Назад к списку
                </b-button>
                <b-button v-if="actualVersion" :disabled="access_level === 1" variant="success" @click="saveData9">
                    Сохранить
                </b-button>
            </div>
            <div v-if="showPKFO3" class="filter-actions">
                <b-button variant="light" @click="returnToList('pkfo3')"><i
                    class="icon icon-keyboard icon-rotate-180"></i>
                    Назад к списку
                </b-button>
            </div>
            <div v-if="showPKFO4" class="filter-actions">
                <b-button variant="light" @click="returnToList('pkfo4')"><i
                    class="icon icon-keyboard icon-rotate-180"></i>
                    Назад к списку
                </b-button>
            </div>
        </div>
        <br><br>
        <div v-if="!showPril7 && !showPril10 && !showPKFO1 && !showPril8 && !showPKFO2 && !showPril9 && !showPKFO3 && !showPKFO4">
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="planPeriod" @click="openFilterByRef('bcPlanYear')">
                  {{ planPeriod }}
                </span>
                <span class="item-breadcrumb" v-if="selectedReg" @click="openFilterByRef('bcRegion')">
                   {{ selectedReg.code }}
                </span>
                <span class="item-breadcrumb" v-if="dataType" @click="openFilterByRef('bcDataType')">
                   {{ dataType.text }}
                </span>
                <span class="item-breadcrumb" v-if="variant" @click="openFilterByRef('bcVariant')">
                   {{ variant.name_ru }}
                </span>
                <span class="item-breadcrumb" v-if="abp" @click="openFilterByRef('bcAbp')">
                   {{ abp.abp }}
                </span>
            </div>

            <div class="table-container fr-table">
                <b-table-simple striped hover bordered>
                    <b-thead>
                        <b-tr>
                            <b-th>Наименование формы</b-th>
                            <b-th>Дата изменений</b-th>
                            <b-th>Автор изменений</b-th>
                            <b-th>Примечание</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="code in ['PR7', 'PR10', 'pkfo1', 'PR8', 'pkfo2', 'PR9', 'pkfo3', 'pkfo4']"
                              :key="code">
                            <b-td>
                                <div>
                                    <span class="title" @click="toggleShow(code)">{{ getTitle(code) }}</span>
                                    <span class="subtitle">{{ getSubtitle(code) }}</span>
                                </div>
                            </b-td>
                            <template v-if="filteredFormList(code).length > 0">
                                <b-td v-for="(field, index) in filteredFormList(code)" :key="index">
                                    {{ formatDate(field.update_date) }}
                                </b-td>
                                <b-td v-for="(field, index) in filteredFormList(code)" :key="index + '-user'">
                                    {{ field.user_id_name }}
                                </b-td>
                            </template>
                            <template v-else>
                                <b-td></b-td>
                                <b-td></b-td>
                            </template>
                            <b-td>
                            <span class="modal-call" @click="openModal(code)">
                                <i v-if="filteredFormList(code).length > 0 && filteredFormList(code)[0].comments_text !== null"
                                   class="csi-icon csi-icon-chat"></i>
                                <i v-else
                                   :class="['icon', 'icon-plus-circle', { 'disabled-icon': !actualVersion }]"
                                   :style="{ opacity: actualVersion ? 1 : 0.5 }">
                                </i>
                            </span>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
        </div>
        <div>
            <forecast-app7 v-if="showPril7"
                           ref="app7"
                           v-bind="forecastProps"
                           @openModal="showModalImport"
                           @checkImpInput="checkImpInput"
                           @updateReport="getForecastReportingForm"
                           @updateLoading="updateLoadingState"
            ></forecast-app7>
            <forecast-app10 v-if="showPril10"
                            ref="app10"
                            v-bind="forecastProps"
            ></forecast-app10>
            <forecast-pkfo1 v-if="showPKFO1"
                            ref="pkfo1"
                            v-bind="forecastProps"></forecast-pkfo1>
            <forecast-app8 v-if="showPril8"
                           ref="app8"
                           v-bind="forecastProps"></forecast-app8>
            <forecast-pkfo2 v-if="showPKFO2"
                            ref="pkfo2"
                            v-bind="forecastProps"></forecast-pkfo2>
            <forecast-app9 v-if="showPril9"
                           ref="app9"
                           v-bind="forecastProps"></forecast-app9>
            <forecast-pkfo3 v-if="showPKFO3"
                            ref="pkfo3"
                            v-bind="forecastProps"></forecast-pkfo3>
            <forecast-pkfo4 v-if="showPKFO4"
                            ref="pkfo4"
                            v-bind="forecastProps"></forecast-pkfo4>
        </div>

        <b-modal v-model="showModal"
                 modal-class="fr-modal-comment"
                 title="Примечание:"
                 centered>
            <div class="comments-content">
                <div class="comments-content" v-if="filteredComments.length > 0">
                    <div class="comment-block" v-for="(comment, index) in filteredComments" :key="index">
                        <p class="label">
                            <span class="mr-1">{{ comment.comments_date }}</span>
                            <span>{{ comment.user_name }}</span></p>
                        <b-form-textarea v-model="comment.comments_text"
                                         @input="updateCommentText(comment.comments_text)"></b-form-textarea>
                    </div>
                </div>
                <div class="comments-content" v-else>
                    <div class="comment-block">
                        <p class="label"></p>
                        <b-form-textarea v-model="comments_text"></b-form-textarea>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <div class="modal-footer-content">
                    <div v-if="actualVersion && (access_level === 2 || access_level === 3)" class="buttons">
                        <b-button variant="light" @click="CancelComment">Отменить</b-button>
                        <b-button variant="success" @click="SaveComment">Сохранить</b-button>
                    </div>
                    <div class="comments-content" v-if="filteredComments.length > 0"
                         style="margin-right: 0; cursor: pointer;">
                        <div class="comment-block" v-for="(comment, index) in filteredComments" :key="index">
                            <p v-if="actualVersion && [2, 3].includes(access_level)"
                               class="label" @click="clearComment(comment)"><i class="icon icon-close"></i> ОЧИСТИТЬ</p>
                        </div>
                    </div>
                    <div class="comments-content" v-else style="margin-right: 0; cursor: pointer;">
                        <div class="comment-block">
                            <p v-if="actualVersion && [2, 3].includes(access_level)"
                               class="label" @click="clearComment(comments_text)"><i class="icon icon-close"></i>
                                ОЧИСТИТЬ</p>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>

        <b-modal v-model="showAddModal"
                 modal-class="fr-modal-comment"
                 title="Добавить примечание"
                 centered>
            <div class="comments-content">
                <div class="comment-block">
                    <b-form-group>
                        <b-form-textarea
                            id="textarea"
                            v-model="text"
                        ></b-form-textarea>
                    </b-form-group>
                </div>
            </div>
            <template #modal-footer>
                <div class="buttons">
                    <b-button variant="light" @click="showAddModal = false">Отменить</b-button>
                    <b-button variant="success">Сохранить</b-button>
                </div>
            </template>
        </b-modal>

        <b-modal v-model="modalConfirm"
                 modal-class="fr-modal-confirm"
                 title="Добавить примечание"
                 hide-footer
                 hide-header
                 size="sm"
                 centered>
            <span class="close-modal" @click="modalConfirm = false">
                <i class="icon icon-close"></i>
            </span>
            <div class="text">
                Импортировать данные
                из бюджетной заявки
                и плана финансирования?
            </div>
            <b-button variant="light" @click="modalConfirm = false">Нет</b-button>
            <b-button variant="success" @click="loadData('Yes')">Да</b-button>
        </b-modal>
        <b-modal v-if="isImport" v-model="modalConfirmUpdate"
                 modal-class="fr-modal-confirm"
                 hide-footer
                 hide-header
                 size="sm"
                 centered>
            <span class="close-modal" @click="modalConfirmUpdate = false">
                <i class="icon icon-close"></i>
            </span>
            <div class="text">При обновлении данных распределение сумм по статьям ПКФО удалится</div>
            <b-button variant="light" @click="modalConfirmUpdate = false">Отмена</b-button>
            <b-button variant="success" @click="loadData(selectedTable)">Ок</b-button>
        </b-modal>
    </div>
</template>

<script>
import ForecastApp7 from '@/modules/forecast-reporting/ForecastApp7';
import ForecastApp10 from "@/modules/forecast-reporting/ForecastApp10";
import ForecastPkfo1 from "@/modules/forecast-reporting/ForecastPKFO-1";
import ForecastApp8 from "@/modules/forecast-reporting/ForecastApp8";
import ForecastPkfo2 from "@/modules/forecast-reporting/ForecastPKFO-2";
import ForecastApp9 from "@/modules/forecast-reporting/ForecastApp9";
import ForecastPkfo3 from "@/modules/forecast-reporting/ForecastPKFO-3";
import ForecastPkfo4 from "@/modules/forecast-reporting/ForecastPKFO-4";
import store from "@/services/store";
import moment from "moment/moment";

export default {
    components: {ForecastApp7, ForecastApp10, ForecastPkfo1, ForecastApp8, ForecastPkfo2, ForecastApp9, ForecastPkfo3, ForecastPkfo4},
    props: {
        access_level: Number,
        abp_cons: Boolean
    },
    name: 'forecast-abp',
    data() {
        return {
            showModal: false,
            showAddModal: false,
            isLoad: false,
            isLoad7: false,
            text: null,
            obl: null,
            formList: [],
            codeForm: null,
            isImport: false,
            modalConfirm: false,
            modalConfirmUpdate: false,
            showPril7: false,
            showPril10: false,
            showPKFO1: false,
            showPKFO2: false,
            showPril8: false,
            showPril9: false,
            showPKFO3: false,
            showPKFO4: false,
            planPeriod: null,
            selectedTable: null,
            planPeriodYears: [],
            selectedReg: null,
            regionList: [],
            dataType: null,
            actualVersion: false,
            actualVariantName: '',
            dataTypeList: [
                {text: 'Прогноз: Формирование бюджета', code: 1},
                {text: 'План: Утверждение бюджета', code: 2},
                {text: 'Уточнение бюджета', code: 3},
                {text: 'Корректировка бюджета', code: 4}
            ],
            year10: null,
            variant: null,
            variantList: [],
            abp: null,
            abpList: [],
            abp_list: [],
            comment: [],
            userName: null,
            filteredComments: [],
            comments_text: '',
            previousCommentText: [],
            input: 214324,
            selectedRadio: 1
        }
    },
    async mounted() {
        await this.getMaxYear();
        await this.getObl();
    },
    computed: {
        filteredFormList() {
            return (code) => this.formList.filter(item => item.code_form === code);
        },
        formattedYears() {
            const year = parseInt(this.planPeriod.split(' - ')[0]);
            return [
                { value: year - 1, text: (year - 1).toString() },
                { value: year, text: year.toString() },
                { value: year + 1, text: (year + 1).toString() },
                { value: year + 2, text: (year + 2).toString() }
            ];
        },
        forecastProps() {
            return {
                year: parseInt(this.planPeriod.split(" - ")[0]),
                region: this.selectedReg.code,
                region_name: this.selectedReg.name_ru,
                version: this.variant.variant_uuid,
                version_name: this.variant.name_ru,
                abp: this.abp.abp,
                dataType: this.dataType.code,
                obl: this.obl,
                actualVersion: this.actualVersion,
                radio_year: this.year10,
                access_level: this.access_level
            };
        }
    },
    watch: {
        planPeriod(newVal) {
            if (newVal) {
                const year = parseInt(newVal.split(' - ')[0]);
                this.year10 = year - 1;
            }
        },
        variant(newVal) {
            this.actualVersion = newVal && 'actual' in newVal;
        }
    },
    methods: {
        formatDate(date) {
            if (date !== null){
                return new Date(date).toLocaleDateString('ru-RU', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
            }
        },
        toggleShow(code) {
            if (code === 'PR7') this.showPril7 = !this.showPril7;
            else if (code === 'PR10') this.showPril10 = !this.showPril10;
            else if (code === 'pkfo1') this.showPKFO1 = !this.showPKFO1;
            else if (code === 'PR8') this.showPril8 = !this.showPril8;
            else if (code === 'pkfo2') this.showPKFO2 = !this.showPKFO2;
            else if (code === 'PR9') this.showPril9 = !this.showPril9;
            else if (code === 'pkfo3') this.showPKFO3 = !this.showPKFO3;
            else if (code === 'pkfo4') this.showPKFO4 = !this.showPKFO4;
        },
        getTitle(code) {
            if (code === 'PR7'){
                return 'Приложение 7';
            } else if (code === 'PR10') {
                return 'Приложение 10';
            } else if (code === 'pkfo1') {
                return 'ПКФО-1';
            } else if (code === 'PR8') {
                return 'Приложение 8';
            } else if (code === 'pkfo2') {
                return 'ПКФО-2';
            } else if (code === 'PR9') {
                return 'Приложение 9';
            } else if (code === 'pkfo3') {
                return 'ПКФО-3';
            } else if (code === 'pkfo4') {
                return 'ПКФО-4';
            }
        },
        getSubtitle(code) {
            if (code === 'PR7'){
                return 'Примеры формирования статей прогнозной консолидированной финансовой отчетности администратора бюджетных программ';
            } else if (code === 'PR10') {
                return 'Расчет сумм амортизации по долгосрочным активам';
            } else if (code === 'pkfo1') {
                return 'Прогнозный консолидированный отчет о финансовом положении';
            } else if (code === 'PR8') {
                return 'Примеры формирования статей прогнозного консолидированного отчета о результатах финансовой деятельности администратора бюджетных программ';
            } else if (code === 'pkfo2') {
                return 'Прогнозный консолидированный отчет о результатах финансовой деятельности';
            } else if (code === 'PR9') {
                return 'Примеры формирования статей прогнозного консолидированного отчета о движении денег (прямой метод) администратора бюджетных программ';
            } else if (code === 'pkfo3') {
                return 'Прогнозный консолидированный отчет о движении денег (прямой метод)';
            } else if (code === 'pkfo4') {
                return 'Прогнозный консолидированный отчет об изменениях чистых активов/капитала';
            }
        },
        handleChange(param) {
            if (param === 'period') {
                this.loadVariantList().then(() => {
                    this.getForecastReportingForm();
                });
            } else {
                Promise.all([this.loadVariantList(), this.loadAbp()])
                    .then(() => {
                        this.getForecastReportingForm();
                    });
            }
        },
        showModalImport() {
            this.modalConfirm = this.actualVersion;
        },
        showModalUpdate(table) {
            this.selectedTable = table;
            if (this.isImport) {
                this.modalConfirmUpdate = true;
            } else {
                if (table === 'Yes420') {
                    this.loadData(this.selectedTable);
                } else if (table === 'YesBC') {
                    this.loadData(this.selectedTable);
                }
            }
        },
        resetButton() {
            this.getMaxYear();
            this.getObl();
        },
        handleApply() {
            if (this.showPril7) {
                if (this.actualVersion){
                    this.loadData('first');
                } else {
                    this.loadData('Yes');
                }
            } else if (this.showPril10) {
                this.loadData10();
            }
            else if (this.showPKFO1) {
                this.$refs.pkfo1.LoadPKFO1();
            }
            else if (this.showPril8) {
                this.$refs.app8.LoadApp8();
            }
            else if (this.showPKFO2) {
                this.$refs.pkfo2.LoadPKFO2();
            }
            else if (this.showPril9) {
                this.$refs.app9.LoadApp9();
            }
            else if (this.showPKFO3) {
                this.$refs.pkfo3.LoadPKFO3();
            }
            else if (this.showPKFO4) {
                this.$refs.pkfo4.LoadPKFO4();
            }
        },
        returnToList(code) {
            if (code === 'PR7') this.showPril7 = !this.showPril7;
            else if (code === 'PR10') this.showPril10 = !this.showPril10;
            else if (code === 'pkfo1') this.showPKFO1 = !this.showPKFO1;
            else if (code === 'PR8') this.showPril8 = !this.showPril8;
            else if (code === 'pkfo2') this.showPKFO2 = !this.showPKFO2;
            else if (code === 'PR9') this.showPril9 = !this.showPril9;
            else if (code === 'pkfo3') this.showPKFO3 = !this.showPKFO3;
            else if (code === 'pkfo4') this.showPKFO4 = !this.showPKFO4;
            this.getForecastReportingForm();
        },
        loadData(answer) {
            this.$refs.app7.LoadApp7(answer);
            this.modalConfirm = false;
            this.modalConfirmUpdate = false;
        },
        loadData10() {
            this.$refs.app10.LoadApp10(this.year10);
            this.modalConfirm = false;
            this.modalConfirmUpdate = false;
        },
        updateLoadingState(value) {
            this.isLoad7 = value;
        },
        saveData7() {
            this.$refs.app7.SaveData();
        },
        saveData10() {
            this.$refs.app10.SaveData();
        },
        savePKFO1() {
            this.$refs.pkfo1.SaveData();
        },
        saveData8() {
            this.$refs.app8.SaveData();
        },
        saveData9() {
            this.$refs.app9.SaveData();
        },
        openModal(code) {
            if (this.actualVersion || this.filteredFormList(code).length !== 0){
                this.comments_text = '';
                this.codeForm = code;
                this.filteredComments = this.formList.filter(comment => comment.code_form === code);

                if (this.filteredComments.length > 0) {
                    this.loadUserNames(this.filteredComments[0].comments_user_id, 'comment', code);
                }

                this.showModal = true;
            }
        },
        checkImpInput(condition){
            this.isImport = condition;
        },
        updateCommentText(newText) {
            this.comments_text = newText;
        },
        clearComment(comment){
            if (this.filteredComments.length > 0){
                comment.comments_text = '';
                this.comments_text = '';
            } else {
                this.comments_text = '';
            }
        },
        CancelComment(){
            this.showModal = false;
            this.getForecastReportingForm();
        },
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            let refItem;
            switch (refName) {
                case 'bcPlanYear':
                    refItem = this.$refs.bcPlanYear;
                    break;
                case 'bcRegion':
                    refItem = this.$refs.bcRegion;
                    break;
                case 'bcVariant':
                    refItem = this.$refs.bcVariant;
                    break;
                case 'bcDataType':
                    refItem = this.$refs.bcDataType;
                    break;
                case 'bcAbp':
                    refItem = this.$refs.bcAbp;
                    break;
                default:
                    break;
            }
            setTimeout(() => refItem.$el.focus(), 100);
        },
        async getMaxYear() {
            try {
                const response = await fetch('/api-py/get_max_year_from_budget_variant');
                if (response.status === 200) {
                    const yearNow = await response.json();
                    const years = [];

                    for (let i = yearNow; i >= 2022; i--) {
                        years.push(`${i} - ${i + 2}`);
                    }
                    this.planPeriodYears = years;
                    this.planPeriod = this.planPeriodYears[0];

                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса MaxYear', error.toString());
            }
        },
        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        this.loadRegions();
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },
        async loadRegions() {
            try {
                const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.$store.getters.user_uuid);
                if (response.status === 200) {
                    this.regionList = await response.json();
                    this.selectedReg = this.regionList[0];
                    await this.loadVariantList();
                    await this.loadAbp();
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        },
        async SaveComment() {
            const checkComment = this.previousCommentText.filter(item => item.code === this.codeForm);
            if (checkComment.length === 0 || checkComment[0].comments_text !== this.filteredComments[0].comments_text){
                this.comment = [];
                const item = Object.assign({});
                this.$set(item, 'code_form', this.codeForm);
                this.$set(item, 'cur_year', parseInt(this.planPeriod.split(' - ')[0]));
                this.$set(item, 'region_code', this.selectedReg.code);
                this.$set(item, 'data_type', parseInt(this.dataType.code));
                this.$set(item, 'budget_variant', this.variant.variant_uuid);
                this.$set(item, 'abp_code', this.abp.abp);
                this.$set(item, 'comments_text', this.comments_text);
                this.$set(item, 'comments_date', moment().format('YYYY-MM-DD'));
                this.$set(item, 'comments_user_id', this.$store.getters.user_uuid);
                this.isLoad = true;
                this.comment.push(item);
                try {
                    const response = await fetch('/api-py/add_comment_to_forecast_reporting_form', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(this.comment)
                    });
                    const result = await response.json();
                    if (response.status === 200 && result.result === 'success') {
                        this.showModal = false;
                        this.isSave = false;
                        this.makeToast('success', 'Сообщение', 'Примечание сохранено');
                        await this.getForecastReportingForm();
                        this.comments_text = '';
                    } else {
                        if (result.result === 'error') {
                            throw 'Ошибка сохранения данных.';
                        }
                    }
                } catch (e) {
                    this.makeToast('danger', 'Предупреждение', e.toString());
                } finally {
                    this.isLoad = false;
                }
            }
        },
        async getForecastReportingForm() {
            this.isLoad = true;
            this.previousCommentText = [];
            if (this.selectedReg !== null && this.dataType !== null && this.planPeriod !== null && this.variant !== null && this.variant !== undefined && this.abp !== null) {
                this.formList = [];
                this.curParams = {
                    cur_year: parseInt(this.planPeriod.split(' - ')[0]),
                    region: this.selectedReg.code,
                    data_type: parseInt(this.dataType.code),
                    variant: this.variant.variant_uuid,
                    abp: this.abp.abp
                }
                const response = await fetch(`/api-py/get_budget_forecast_reporting_form/` + JSON.stringify(this.curParams));
                const data = await response.json();
                this.formList = data.result;
                const formCodes = ['PR7', 'PR10', 'pkfo1', 'PR8', 'pkfo2', 'PR9', 'pkfo3', 'pkfo4'];
                for (const form_code of formCodes) {
                    const filteredList = this.formList.filter(item => item.code_form === form_code);
                    for (const item of filteredList) {
                        this.previousCommentText.push({
                            code: item.code_form,
                            comments_text: item.comments_text,
                        });
                        if (item.user_id !== null){
                            await this.loadUserNames(item.user_id, 'user_id', form_code);
                        }
                    }
                }
            }
            this.isLoad = false;
        },
        async loadUserNames(user_id, field, form_code) {
            try {
                const response = await fetch(`/api-py/get-realm-user/${user_id}`);
                if (response.status === 200) {
                    const data = await response.json();
                    if (field === 'comment') {
                        this.filteredComments.forEach(comment => {
                            this.$set(comment, 'user_name', data.lastName + ' ' + data.firstName);
                        });
                    }
                    if (field === 'user_id') {
                        this.formList.forEach(code => {
                            if (code.user_id === user_id && code.code_form === form_code) {
                                this.$set(code, 'user_id_name', data.lastName + ' ' + data.firstName);
                            }
                        });
                    }
                } else {
                    if (field === 'comment') {
                        this.filteredComments.forEach(comment => {
                            this.$set(comment, 'user_name', '');
                        });
                    }
                    if (field === 'user_id') {
                        this.formList.forEach(code => {
                            this.$set(code, 'user_id_name', '');
                        });
                    }
                }
            } catch (error) {
                if (field === 'comment') {
                    this.filteredComments.forEach(comment => {
                        this.$set(comment, 'user_name', '');
                    });
                }
                if (field === 'user_id') {
                    this.formList.forEach(code => {
                        this.$set(code, 'user_id_name', '');
                    });
                }
            }
        },
        async loadAbp() {
            if (this.selectedReg !== null) {
                try {
                    if (this.abp_cons === true){
                        const abpListResponse = await fetch('/api-py/get_abp_access_pkfo_by_user_gu_link/' + this.$store.getters.user_uuid + '/' + this.variant.variant_uuid);
                        if (abpListResponse.status === 200) {
                            this.abp_list = await abpListResponse.json();
                        }
                    } else {
                        const abpListResponse = await fetch('/api-py/get_abp_access_pkfo/' + this.$store.getters.user_uuid + '/' + this.variant.variant_uuid);
                        if (abpListResponse.status === 200) {
                            this.abp_list = await abpListResponse.json();
                        }
                    }

                    const response = await fetch('/api-py/get_abp_by_region_user_id/' + this.selectedReg.code + '/' + this.$store.getters.user_uuid + '/' + 0);
                    if (response.status === 200) {
                        this.abpList = await response.json();
                        this.abpList = this.abpList.filter(option => this.abp_list.includes(String(option.abp)));
                        if (this.abpList.length > 0){
                            this.abp = this.abpList[0];
                        } else {
                            this.abp = null;
                            this.makeToast('warning', 'Предупреждение', 'Нет доступа к АБП');
                        }
                    }
                    await this.getForecastReportingForm();
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса АБП', error.toString());
                }
            }
        },
        async loadVariantList() {
            this.isLoad = true;
            if (this.selectedReg !== null && this.planPeriod !== null) {
                try {
                    const response = await fetch(`/api-py/get_budget_variant_for_forecast_reporting_form/` + this.selectedReg.code + '/' + parseInt(this.planPeriod.split(' - ')[0]));
                    const data = await response.json();
                    this.variantList = data.result;
                    this.variant = this.variantList[0];
                    if ('actual' in this.variant) {
                        this.actualVersion = true;
                        this.actualVariantName = this.variant.name_ru;
                    }
                    const matchedDataType = this.dataTypeList.find(item => item.code === this.variant.data_type);

                    if (matchedDataType) {
                        this.dataType = matchedDataType;
                    } else {
                        this.dataType = null;
                    }
                } catch (error) {
                    this.variant = null;
                    this.makeToast('danger', 'Ошибка', 'Версия бюджета не найдена');
                }
            }
            this.isLoad = false;
        },
        async loadVariantByDataType() {
            this.isLoad = true;
            if (this.selectedReg !== null && this.planPeriod !== null && this.dataType) {
                try {
                    const response = await fetch(`/api-py/get-variants-by-data-type/` + this.selectedReg.code + '/' + this.dataType.code + '/' + parseInt(this.planPeriod.split(' - ')[0]));
                    this.variantList = await response.json();
                    this.variantList.forEach((item) => {
                        if (item.is_deleted === false && item.attribute === true) {
                            item.name_ru += ' (акт.)';
                            item.actual = true;
                            this.variant = item;
                        } else {
                            this.variant = this.variantList[0];
                        }
                    });
                } catch (error) {
                    this.variant = null;
                    this.makeToast('danger', 'Ошибка', 'Версия бюджета не найдена');
                }
            }
            this.isLoad = false;
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
    }
}
</script>

<style>
.modal-footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.comments-content {
    cursor: pointer;
    margin-right: 0;
}
</style>