<template>
    <div>
        <div class="actions-tab in-header">
            <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-info"></i>
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
            </b-dropdown>
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="downloadRep">Приложение 8</b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="table-container">
            <table class="table b-table table-bordered b-table-no-border-collapse">
                <thead class="sticky-thead">
                <tr>
                    <th v-for="(field, index) in tableFields" class="text-center" :key="index"
                        :rowspan="field.rowspan || 1" :colspan="field.colspan || 1"
                        v-html="field.key === 'value' ? getEvaluationLabel() : field.label">
                    </th>
                </tr>
                <tr>
                    <th v-for="year in forecastYears" class="text-center" :key="year">{{ year }}</th>
                </tr>
                </thead>
                <tbody v-for="(row, index) in formApp8" :key="index" class="table-info"
                       :style="{
                        backgroundColor:
                          row.is_calculation === 'calc'
                            ? (row.pkfo_2_code.endsWith('00') ? '' : '#e8f4fa')
                            : 'white'
                      }">
                <tr>
                    <td>{{ row.code }}</td>
                    <td v-if="row.spf === null" :colspan="2">{{ row.pkfo_2_code}} {{row.pkfo2_name}}</td>
                    <td v-else>{{ row.pkfo_2_code}} {{row.pkfo2_name}}</td>
                    <td v-if="row.spf !== null">{{ row.spf }} {{row.spf_name_ru}}</td>
                    <td>{{ row.name_ru}}</td>
                    <td v-if="actualVersion && row.is_calculation === 'input'">
                        <input type="number"
                               v-model="row.value"
                               :class="{ 'disabled-input': access_level === 1 }"
                               :disabled="access_level === 1"
                               @input="formatDecimal(row, 'value')"
                               @blur="blurInput(row, 'value')"
                               class="form-control no-spinner" style="text-align: right;" step="0.1"/>
                    </td>
                    <td v-else-if="row.value" class="text-right">{{ $n(toNum(row.value)) }}</td>
                    <td v-else></td>
                    <td v-if="actualVersion && row.is_calculation === 'input'">
                        <input type="number"
                               v-model="row.forecast_1"
                               :class="{ 'disabled-input': access_level === 1 }"
                               :disabled="access_level === 1"
                               @input="formatDecimal(row, 'forecast_1')"
                               @blur="blurInput(row, 'forecast_1')"
                               class="form-control no-spinner" style="text-align: right;" step="0.1"/>
                    </td>
                    <td v-else-if="row.forecast_1" class="text-right">{{ $n(toNum(row.forecast_1)) }}</td>
                    <td v-else></td>
                    <td v-if="actualVersion && row.is_calculation === 'input'">
                        <input type="number"
                               v-model="row.forecast_2"
                               :class="{ 'disabled-input': access_level === 1 }"
                               :disabled="access_level === 1"
                               @input="formatDecimal(row, 'forecast_2')"
                               @blur="blurInput(row, 'forecast_2')"
                               class="form-control no-spinner" style="text-align: right;" step="0.1"/>
                    </td>
                    <td v-else-if="row.forecast_2" class="text-right">{{ $n(toNum(row.forecast_2)) }}</td>
                    <td v-else></td>
                    <td v-if="actualVersion && row.is_calculation === 'input'">
                        <input type="number"
                               v-model="row.forecast_3"
                               :class="{ 'disabled-input': access_level === 1 }"
                               :disabled="access_level === 1"
                               @input="formatDecimal(row, 'forecast_3')"
                               @blur="blurInput(row, 'forecast_3')"
                               class="form-control no-spinner" style="text-align: right;" step="0.1"/>
                    </td>
                    <td v-else-if="row.forecast_3" class="text-right">{{ $n(toNum(row.forecast_3)) }}</td>
                    <td v-else></td>
                </tr>
                </tbody>
            </table>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
    </div>
</template>
<script>

import VueElementLoading from "vue-element-loading";
import moment from "moment/moment";
import {Ax} from "@/utils";

export default {
    name: 'forecast-app8',
    components: {
        'loading': VueElementLoading
    },
    props: {
        year: Number,
        region: String,
        version: String,
        abp: Number,
        dataType: Number,
        region_name: String,
        version_name: String,
        obl: String,
        actualVersion: Boolean,
        access_level: Number
    },
    data() {
        return {
            loading: false,
            open: false,
            tableFields: [
                {
                    key: 'code',
                    label: '№',
                    rowspan: 2
                },
                {
                    key: 'pkfo_2_code',
                    label: 'Строка из прогнозного консолидированного отчета о результатах финансовой деятельности',
                    rowspan: 2
                },
                {
                    key: 'spf',
                    label: 'Специфика экономической классификации Единой бюджетной классификации ',
                    rowspan: 2
                },
                {
                    key: 'name_ru',
                    label: 'Сумма по строке прогнозного отчета о результатах финансовой деятельности',
                    rowspan: 2
                },
                {
                    key: 'value',
                    label: 'Оценка*, ТЫС.ТЕНГЕ',
                    rowspan: 1
                },
                {
                    key: 'forecast',
                    label: 'Прогноз, ТЫС.ТЕНГЕ',
                    colspan: 3
                }],
            formApp8: [],
            repdate: null,
            openRows: {},
            inputValues: []

        }
    },
    async mounted() {
        await this.LoadApp8();
    },
    computed: {
        forecastYears() {
            return [this.year - 1, this.year, this.year + 1, this.year + 2];
        }
    },
    methods: {
        getEvaluationLabel() {
            if (!this.repdate) {
                return `Оценка*, тыс.тенге`;
            }
            return `<span v-b-tooltip.hover title="Оценочные данные\nактуальны на дату:\n${this.formatDate(this.repdate)}"
                style="cursor: pointer;">Оценка*, тыс.тенге</span>`;
        },
        formatDate(date) {
            if (date !== null){
                return new Date(date).toLocaleDateString('ru-RU', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
            }
        },
        formatDecimal(child, field) {
            let value = child[field].toString();
            if (value.includes('.')) {
                value = value.replace(/(\.\d).*/, '$1'); // Keep only one digit after '.'
            }
            child[field] = value;
        },
        blurInput(child, data, isInitialLoad = false) {
            let parsedValue;
            if (child[data] === undefined || child[data] === null || child[data] === "") {
                parsedValue = null;
            } else {
                parsedValue = parseFloat(child[data]) || 0;
            }

            if (!isInitialLoad && child[data] !== null && child[data] !== 0) {
                const existingEntryIndex = this.inputValues.findIndex(entry => entry.code === child.code);
                if (existingEntryIndex !== -1) {
                    // Update the specific field for an existing entry
                    this.$set(this.inputValues[existingEntryIndex], data, parsedValue);
                } else if (parsedValue !== null) {
                    // Create a new entry if it doesn't exist and the value is not null
                    const newEntry = {
                        code: child.code,
                        value: data === 'value' ? parsedValue : null,
                        forecast_1: data === 'forecast_1' ? parsedValue : null,
                        forecast_2: data === 'forecast_2' ? parsedValue : null,
                        forecast_3: data === 'forecast_3' ? parsedValue : null
                    };
                    this.inputValues.push(newEntry);
                }
            }

            if (child.pkfo_2_code >= '010' && child.pkfo_2_code <= '040'){
                const parentRow = child.pkfo_2_code.substring(0, 2);
                const totalRow = this.formApp8.find(item => item.is_calculation === 'calc' && item.pkfo_2_code.startsWith(parentRow));
                const sumRows = this.formApp8.filter(item => item.is_calculation !== 'calc' && item.pkfo_2_code.startsWith(parentRow));
                if (totalRow && sumRows) {
                    const res = sumRows.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                    this.$set(totalRow, data, this.toNum(res));
                }
                const mainRow = this.formApp8.find(item => item.is_calculation === 'calc' && item.pkfo_2_code === '100');
                const totalRows = this.formApp8.filter(item => ['010', '020', '030', '040'].includes(item.pkfo_2_code));
                if (mainRow && totalRows) {
                    const res = totalRows.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                    this.$set(mainRow, data, this.toNum(res));
                }
            }
            if (child.pkfo_2_code >= '110' && child.pkfo_2_code <= '150'){
                const totalRow = this.formApp8.find(item => item.is_calculation === 'calc' && item.pkfo_2_code === child.pkfo_2_code);
                const sumRows = this.formApp8.filter(item => item.is_calculation !== 'calc' && item.pkfo_2_code === child.pkfo_2_code);
                if (totalRow && sumRows) {
                    const res = sumRows.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                    this.$set(totalRow, data, this.toNum(res));
                }
                const mainRow = this.formApp8.find(item => item.is_calculation === 'calc' && item.pkfo_2_code === '200');
                const totalRows = this.formApp8.filter(item => ['110', '130', '140', '150'].includes(item.pkfo_2_code));
                if (mainRow && totalRows) {
                    const res = totalRows.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                    this.$set(mainRow, data, this.toNum(res));
                }
                const mainRow11 = this.formApp8.find(item => item.pkfo_2_code === '110');
                const totalRows11 = this.formApp8.filter(item => {
                    const code = Number(item.pkfo_2_code);
                    return code > 110 && code < 130 && !item.code.includes('.');
                });
                if (mainRow11 && totalRows11) {
                    const res = totalRows11.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                    this.$set(mainRow11, data, this.toNum(res));
                }
                const mainRow13 = this.formApp8.find(item => item.pkfo_2_code === '130');
                const totalRows13 = this.formApp8.filter(item => {
                    const code = Number(item.pkfo_2_code);
                    return code > 130 && code < 140 && !item.code.includes('.');
                });
                if (mainRow13 && totalRows13) {
                    const res = totalRows13.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                    this.$set(mainRow13, data, this.toNum(res));
                }
                const mainRow14 = this.formApp8.find(item => item.pkfo_2_code === '140');
                const totalRows14 = this.formApp8.filter(item => {
                    const code = Number(item.pkfo_2_code);
                    return code > 140 && code < 150 && !item.code.includes('.');
                });
                if (mainRow14 && totalRows14) {
                    const res = totalRows14.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                    this.$set(mainRow14, data, this.toNum(res));
                }
            }
            if (child.pkfo_2_code >= '210' && child.pkfo_2_code <= '240') {
                const mainRow3 = this.formApp8.find(item => item.is_calculation === 'calc' && item.pkfo_2_code === '300');
                const mainRow1 = this.formApp8.find(item => item.is_calculation === 'calc' && item.pkfo_2_code === '100');
                const mainRow2 = this.formApp8.find(item => item.is_calculation === 'calc' && item.pkfo_2_code === '200');

                // Filter rows where pkfo_2_code is between 210 and 240
                const totalRows = this.formApp8.filter(item => {
                    const code = Number(item.pkfo_2_code);
                    return code >= 210 && code <= 240;
                });

                if (mainRow3 && mainRow1 && mainRow2 && totalRows.length > 0) {
                    const res = totalRows.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                    const mainRow1Value = parseFloat(mainRow1[data]) || 0;
                    const mainRow2Value = parseFloat(mainRow2[data]) || 0;

                    // Calculate and set the value for mainRow3
                    this.$set(mainRow3, data, this.toNum(mainRow1Value - mainRow2Value + res));
                }
            }

        },
        async LoadApp8() {
            this.loading = true;
            try {
                const response = await fetch(`/api-py/get_dict_items_appendix8/` + this.year + '/' + this.version + '/' + this.abp);
                if (response.status === 200) {
                    const data = await response.json();
                    this.formApp8 = data.result;
                    this.inputValues = data.input;
                    this.repdate = data.repdate;
                    const keysToBlur = ['value', 'forecast_1', 'forecast_2', 'forecast_3'];

                    const applyBlur = (row) => {
                        keysToBlur.forEach(key => {
                            if (key in row) {
                                this.blurInput(row, key, true);
                            }
                        });
                    };

                    this.formApp8.forEach(applyBlur);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка Приложения 8', error.toString());
            }
            this.loading = false;
        },
        async SaveData() {
            if (Object.keys(this.inputValues).length > 0) {
                this.inputData = [];
                const item = Object.assign({});

                this.$set(item, 'inputValues', this.inputValues);
                this.$set(item, 'cur_year', this.year);
                this.$set(item, 'budget_variant', this.version);
                this.$set(item, 'abp_code', this.abp);
                this.$set(item, 'user_id', this.$store.getters.user_uuid);
                this.$set(item, 'update_date', moment().format('YYYY-MM-DD'));
                this.$set(item, 'code_form', 'PR8');
                this.$set(item, 'region_code', this.region);
                this.$set(item, 'data_type', this.dataType);
                this.isLoad = true;
                this.inputData.push(item);
                try {
                    const response = await fetch('/api-py/add_data_to_appendix8', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(this.inputData)
                    });
                    const result = await response.json();
                    if (response.status === 200 && result.result === 'success') {
                        this.isSave = false;
                        await this.LoadApp8();
                        this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    } else {
                        if (result.result === 'error') {
                            throw 'Ошибка сохранения данных.';
                        }
                    }
                } catch (e) {
                    this.makeToast('danger', 'Предупреждение', e.toString());
                } finally {
                    this.isLoad = false;
                }
            }

        },
        downloadRep() {
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.isLoad = true;
                this.curParams = { year: this.year, region: this.region, version: this.version, abp: this.abp, data_type: this.dataType, reg_name: this.region_name, ver_name: this.version_name, obl: this.obl };
                Ax(
                    {
                        url: '/api-py/forecast_reporting_download_app8/' + JSON.stringify(this.curParams),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Приложение 8.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
            } finally {
                this.isLoad = false
            }
        },
        toggleRow(index) {
            this.$set(this.openRows, index, !this.openRows[index]);
        },
        toNum(value, fix = 1) {
            if (typeof (value) === 'string') {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof (value) === 'number') {
                return parseFloat(value.toFixed(fix));
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if (typeof (value) === 'undefined') {
                return 0;
            }
            if (isNaN(value)) {
                return 0;
            }
            if (isFinite(value)) {
                return 0;
            }
            if (value === null) {
                return 0;
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
    }
}
</script>
