<template>
    <div class="inner-container forecast-reporting">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджетное планирование</span>
        </div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>Прогнозная консолидированная финансовая отчетность</span>
        </div>
        <b-tabs>
            <b-tab v-if="abp_level && abpTab" title="ПКФО АБП">
                <ForecastAbp
                    :access_level="access_level"
                    :abp_cons="abp_cons"></ForecastAbp>
            </b-tab>
            <b-tab v-if="obl_level" title="ПКФО УО">
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import ForecastAbp from "@/modules/forecast-reporting/ForecastAbp";

export default {
    components: {ForecastAbp},
    name: 'forecast-reporting',
    data() {
        return {
            abpTab: false,
            oblTab: false,
            abp_level: false,
            obl_level: false,
            abp_cons: false,
            access_level: 1,
            access_levelObl: 1,
        }
    },
    async mounted() {
        await this.getAccessLevelABP();
        await this.getPkfoAbpAccess();
        await this.getPkfoAbpAccessCons();
        await this.getAccessLevelObl();
    },
    methods: {
        async getPkfoAbpAccess() {
            try {
                const response = await fetch(`/api-py/get_access_by_user_id_to_pkfo/${this.$store.getters.user_uuid}`);
                const abp_pkfo = await response.json();
                if (abp_pkfo.length > 0){
                    this.abpTab = true;
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса pkfo_abp', error.toString());
            }
        },
        async getPkfoAbpAccessCons() {
            try {
                const response = await fetch(`/api-py/get_access_by_user_id_to_pkfo_cons/${this.$store.getters.user_uuid}`);
                const abp_pkfo = await response.json();
                if (abp_pkfo.length > 0){
                    this.abpTab = true;
                    this.abp_cons = true;
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса pkfo_abp', error.toString());
            }
        },
        async getAccessLevelABP() {
            try {
                const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/004.009.001`);

                const modules_access = await response.json();
                if (modules_access.length > 1) {
                    this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
                }
                if (modules_access.length === 0) {
                    this.access_level = 1;
                } else {
                    for (const ma of modules_access) {
                        this.access_level = ma.access_level;
                        this.abp_level = true;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            }
        },
        async getAccessLevelObl() {
            try {
                const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/004.009.002`);

                const modules_access = await response.json();
                if (modules_access.length > 1) {
                    this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
                }
                if (modules_access.length === 0) {
                    this.access_levelObl = 1;
                } else {
                    for (const ma of modules_access) {
                        this.access_levelObl = ma.access_level;
                        this.obl_level = true;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            }
        }
    }
}
</script>
