<template>
    <div>
        <div class="actions-tab in-header">
            <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-info"></i>
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
            </b-dropdown>
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="downloadRep">ПКФО-3</b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="table-container">
            <table class="table b-table table-bordered b-table-no-border-collapse">
                <thead class="sticky-thead">
                <tr>
                    <th v-for="(field, index) in tableFields" class="text-center" :key="index"
                        :rowspan="field.rowspan || 1" :colspan="field.colspan || 1"
                        :style="{ width: index === 0 ? '800px' : index === 2 ? '200px' : '' }"
                        v-html="field.key === 'evaluation' ? getEvaluationLabel() : field.label">
                    </th>
                </tr>
                <tr>
                    <th v-for="year in forecastYears" class="text-center" :key="year">{{ year }}</th>
                </tr>
                </thead>
                <tbody v-for="(row, index) in formPkfo3" :key="index" class="table-info"
                       :style="{
                          backgroundColor:
                            row.line_code === null || row.line_code === '910' ? ''
                              : row.line_code.endsWith('00')
                              ? '#e8f4fa' : 'white'}">
                <tr>
                    <td v-if="row.line_code === null" colspan="7">{{ row.name_ru }}</td>
                    <template v-else>
                        <td class="text-left">{{ row.name_ru }}</td>
                        <td class="text-center">{{ row.line_code }}</td>
                        <td class="text-right" v-if="row.fact">{{ $n(toNum(row.fact)) }}</td>
                        <td v-else></td>
                        <td class="text-right" v-if="row.evaluation">{{ $n(toNum(row.evaluation)) }}</td>
                        <td v-else></td>
                        <td class="text-right" v-if="row.forecast_1">{{ $n(toNum(row.forecast_1)) }}</td>
                        <td v-else></td>
                        <td class="text-right" v-if="row.forecast_2">{{ $n(toNum(row.forecast_2)) }}</td>
                        <td v-else></td>
                        <td class="text-right" v-if="row.forecast_3">{{ $n(toNum(row.forecast_3)) }}</td>
                        <td v-else></td>
                    </template>
                </tr>
                </tbody>
            </table>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
    </div>
</template>
<script>

import VueElementLoading from "vue-element-loading";
import {Ax} from "@/utils";

export default {
    name: 'forecast-pkfo3',
    components: {
        'loading': VueElementLoading
    },
    props: {
        year: Number,
        region: String,
        version: String,
        abp: Number,
        dataType: Number,
        region_name: String,
        version_name: String,
        obl: String,
        actualVersion: Boolean
    },
    data() {
        return {
            loading: false,
            open: false,
            tableFields: [
                {
                    key: 'name_ru',
                    label: 'Показатели',
                    rowspan: 2
                },
                {
                    key: 'line_code',
                    label: 'Код строки',
                    rowspan: 2
                },
                {
                    key: 'fact',
                    label: 'Факт, ТЫС.ТЕНГЕ',
                    rowspan: 1
                },
                {
                    key: 'evaluation',
                    label: 'Оценка*, ТЫС.ТЕНГЕ',
                    rowspan: 1
                },
                {
                    key: 'forecast',
                    label: 'Прогноз, ТЫС.ТЕНГЕ',
                    colspan: 3
                }],
            formPkfo3: [],
            repdate: null,
            openRows: {},
            inputValues: []

        }
    },
    async mounted() {
        await this.LoadPKFO3();
    },
    computed: {
        forecastYears() {
            return [this.year - 2, this.year - 1, this.year, this.year + 1, this.year + 2];
        }
    },
    methods: {
        getEvaluationLabel() {
            if (!this.repdate) {
                return `Оценка*, тыс.тенге`;
            }
            return `<span v-b-tooltip.hover title="Оценочные данные\nактуальны на дату:\n${this.formatDate(this.repdate)}"
                style="cursor: pointer;">Оценка*, тыс.тенге</span>`;
        },
        formatDate(date) {
            if (date !== null){
                return new Date(date).toLocaleDateString('ru-RU', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
            }
        },
        async LoadPKFO3() {
            this.loading = true;
            try {
                const response = await fetch(`/api-py/get_dict_items_pkfo3/` + this.version + '/' + this.abp + '/' + this.year + '/' + this.obl + '/' + this.region);
                if (response.status === 200) {
                    const data = await response.json();
                    this.formPkfo3 = data.result;
                    this.repdate = data.repdate;
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка ПКФО-3', error.toString());
            }
            this.loading = false;
        },
        downloadRep() {
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.isLoad = true;
                this.curParams = { year: this.year, region: this.region, version: this.version, abp: this.abp, data_type: this.dataType, reg_name: this.region_name, ver_name: this.version_name, obl: this.obl };
                Ax(
                    {
                        url: '/api-py/forecast_reporting_download_pkfo_3/' + JSON.stringify(this.curParams),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'ПКФО-3.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
            } finally {
                this.isLoad = false
            }
        },
        toggleRow(index) {
            this.$set(this.openRows, index, !this.openRows[index]);
        },
        toNum(value, fix = 1) {
            if (typeof (value) === 'string') {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof (value) === 'number') {
                return parseFloat(value.toFixed(fix));
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if (typeof (value) === 'undefined') {
                return 0;
            }
            if (isNaN(value)) {
                return 0;
            }
            if (isFinite(value)) {
                return 0;
            }
            if (value === null) {
                return 0;
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
    }
}
</script>