<template>
    <div>
        <div class="actions-tab in-header">
            <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-info"></i>
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
            </b-dropdown>
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="downloadRep">Приложение 9</b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="table-container">
            <table class="table b-table table-bordered b-table-no-border-collapse">
                <thead class="sticky-thead">
                <tr>
                    <th v-for="(field, index) in tableFields" class="text-center" :key="index"
                        :rowspan="field.rowspan || 1" :colspan="field.colspan || 1"
                        v-html="field.key === 'value' ? getEvaluationLabel() : field.label">
                    </th>
                </tr>
                <tr>
                    <th v-for="year in forecastYears" class="text-center" :key="year">{{ year }}</th>
                </tr>
                </thead>
                <tbody v-for="(row, index) in formApp9" :key="index" class="table-info">
                <tr>
                    <!-- Parent Row -->
                    <td class="toggle-show">
                        <button type="button" class="btn btn-secondary" @click="toggleRow(index)">
                            <i class="icon icon-chevron-circle"></i>
                        </button>
                    </td>
                    <td class="text-center">{{ row.code }}</td>
                    <td colspan="7">
                        <div>{{ row.name_ru }}</div>
                    </td>
                </tr>
                <template v-if="openRows[index]">
                    <tr v-for="(child, childIndex) in row.child" :key="childIndex"
                        :style="{
                              backgroundColor:
                                child.is_calculation === 'calc' && child.pkfo_3_code.endsWith('00')
                                  ? '#e8f4fa'
                                  : (child.is_calculation === 'calc' && child.spf === null && child.pkfo_3_code !== '010'
                                    ? '#f0f9ff'
                                    : 'white')
                            }">
                        <td></td>
                        <td class="text-center">{{ child.code }}</td>
                        <td v-if="child.spf === null" :colspan="2">{{ child.pkfo_3_code}} {{child.pkfo3_name}}</td>
                        <td v-else>{{ child.pkfo_3_code}} {{child.pkfo3_name}}</td>
                        <td v-if="child.spf !== null">{{ child.spf }} {{child.spf_name_ru}}</td>
                        <td>{{ child.name_ru}}</td>
                        <td v-if="actualVersion && child.is_calculation === 'input'">
                            <input type="number"
                                   v-model="child.value"
                                   :class="{ 'disabled-input': access_level === 1 }"
                                   :disabled="access_level === 1"
                                   @input="formatDecimal(child, 'value')"
                                   @blur="blurInput(child, 'value')"
                                   class="form-control no-spinner" style="text-align: right;" step="0.1"/>
                        </td>
                        <td v-else-if="child.value" class="text-right">{{ $n(toNum(child.value)) }}</td>
                        <td v-else></td>
                        <td v-if="actualVersion && child.is_calculation === 'input'">
                            <input type="number"
                                   v-model="child.forecast_1"
                                   :class="{ 'disabled-input': access_level === 1 }"
                                   :disabled="access_level === 1"
                                   @input="formatDecimal(child, 'forecast_1')"
                                   @blur="blurInput(child, 'forecast_1')"
                                   class="form-control no-spinner" style="text-align: right;" step="0.1"/>
                        </td>
                        <td v-else-if="child.forecast_1" class="text-right">{{ $n(toNum(child.forecast_1)) }}</td>
                        <td v-else></td>
                        <td v-if="actualVersion && child.is_calculation === 'input'">
                            <input type="number"
                                   v-model="child.forecast_2"
                                   :class="{ 'disabled-input': access_level === 1 }"
                                   :disabled="access_level === 1"
                                   @input="formatDecimal(child, 'forecast_2')"
                                   @blur="blurInput(child, 'forecast_2')"
                                   class="form-control no-spinner" style="text-align: right;" step="0.1"/>
                        </td>
                        <td v-else-if="child.forecast_2" class="text-right">{{ $n(toNum(child.forecast_2)) }}</td>
                        <td v-else></td>
                        <td v-if="actualVersion && child.is_calculation === 'input'">
                            <input type="number"
                                   v-model="child.forecast_3"
                                   :class="{ 'disabled-input': access_level === 1 }"
                                   :disabled="access_level === 1"
                                   @input="formatDecimal(child, 'forecast_3')"
                                   @blur="blurInput(child, 'forecast_3')"
                                   class="form-control no-spinner" style="text-align: right;" step="0.1"/>
                        </td>
                        <td v-else-if="child.forecast_3" class="text-right">{{ $n(toNum(child.forecast_3)) }}</td>
                        <td v-else></td>
                    </tr>
                </template>
                </tbody>
            </table>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
    </div>
</template>
<script>

import VueElementLoading from "vue-element-loading";
import moment from "moment/moment";
import {Ax} from "@/utils";

export default {
    name: 'forecast-app9',
    components: {
        'loading': VueElementLoading
    },
    props: {
        year: Number,
        region: String,
        version: String,
        abp: Number,
        dataType: Number,
        region_name: String,
        version_name: String,
        obl: String,
        actualVersion: Boolean,
        access_level: Number
    },
    data() {
        return {
            loading: false,
            open: false,
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                    class: 'toggle-show',
                    rowspan: 2
                },
                {
                    key: 'code',
                    label: '№',
                    rowspan: 2
                },
                {
                    key: 'pkfo_3_code',
                    label: 'Строка из прогнозного отчета о движении денег (прямой метод)',
                    rowspan: 2
                },
                {
                    key: 'spf',
                    label: 'Специфика экономической классификации Единой бюджетной классификации ',
                    rowspan: 2
                },
                {
                    key: 'name_ru',
                    label: 'Сумма по строке прогнозного отчета о движении денег (прямой метод)',
                    rowspan: 2
                },
                {
                    key: 'value',
                    label: 'Оценка*, ТЫС.ТЕНГЕ',
                    rowspan: 1
                },
                {
                    key: 'forecast',
                    label: 'Прогноз, ТЫС.ТЕНГЕ',
                    colspan: 3
                }],
            formApp9: [],
            repdate: null,
            openRows: {},
            inputValues: []

        }
    },
    async mounted() {
        await this.LoadApp9();
        this.formApp9.forEach((row, index) => {
            this.$set(this.openRows, index, true);
        });
    },
    computed: {
        forecastYears() {
            return [this.year - 1, this.year, this.year + 1, this.year + 2];
        }
    },
    methods: {
        getEvaluationLabel() {
            if (!this.repdate) {
                return `Оценка*, тыс.тенге`;
            }
            return `<span v-b-tooltip.hover title="Оценочные данные\nактуальны на дату:\n${this.formatDate(this.repdate)}"
                style="cursor: pointer;">Оценка*, тыс.тенге</span>`;
        },
        formatDate(date) {
            if (date !== null){
                return new Date(date).toLocaleDateString('ru-RU', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
            }
        },
        formatDecimal(child, field) {
            let value = child[field].toString();
            if (value.includes('.')) {
                value = value.replace(/(\.\d).*/, '$1'); // Keep only one digit after '.'
            }
            child[field] = value;
        },
        blurInput(child, data, isInitialLoad = false) {
            let parsedValue;
            if (child[data] === undefined || child[data] === null || child[data] === "") {
                parsedValue = null;
            } else {
                parsedValue = parseFloat(child[data]) || 0;
            }

            if (!isInitialLoad && child[data] !== null && child[data] !== 0) {
                const existingEntryIndex = this.inputValues.findIndex(entry => entry.code === child.code);
                if (existingEntryIndex !== -1) {
                    // Update the specific field for an existing entry
                    this.$set(this.inputValues[existingEntryIndex], data, parsedValue);
                } else if (parsedValue !== null) {
                    // Create a new entry if it doesn't exist and the value is not null
                    const newEntry = {
                        code: child.code,
                        value: data === 'value' ? parsedValue : null,
                        forecast_1: data === 'forecast_1' ? parsedValue : null,
                        forecast_2: data === 'forecast_2' ? parsedValue : null,
                        forecast_3: data === 'forecast_3' ? parsedValue : null
                    };
                    this.inputValues.push(newEntry);
                }
            }
            // 010
            const mainRow = this.formApp9[0].child.find(item => item.is_calculation === 'calc' && item.pkfo_3_code === '010');
            const totalRows = this.formApp9[0].child.filter(item => ['011', '012', '013', '014', '015', '016'].includes(item.pkfo_3_code));
            if (mainRow && totalRows) {
                const res = totalRows.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                this.$set(mainRow, data, this.toNum(res));
            }
            // 100
            const totalRow10 = this.formApp9[0].child.find(item => item.is_calculation === 'calc' && item.pkfo_3_code === '100');
            const sumRows10 = this.formApp9[0].child.filter(item => ['010', '017', '020', '030', '040', '050', '060'].includes(item.pkfo_3_code));
            if (totalRow10 && sumRows10) {
                const res = sumRows10.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                this.$set(totalRow10, data, this.toNum(res));
            }
            // 110 - 190
            const mainRow11 = this.formApp9[0].child.find(item => item.is_calculation === 'calc' && item.pkfo_3_code === child.pkfo_3_code);
            const totalRows11 = this.formApp9[0].child.filter(item => item.is_calculation !== 'calc' && item.pkfo_3_code === child.pkfo_3_code);
            if (mainRow11 && totalRows11) {
                const res = totalRows11.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                this.$set(mainRow11, data, this.toNum(res));
            }
            // 200
            const totalRow20 = this.formApp9[0].child.find(item => item.is_calculation === 'calc' && item.pkfo_3_code === '200');
            const sumRows20 = this.formApp9[0].child.filter(item => {
                            const code = Number(item.pkfo_3_code);
                            return code >= 110 && code <= 190 && item.is_calculation === 'calc';
                        });
            if (totalRow20 && sumRows20) {
                const res = sumRows20.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                this.$set(totalRow20, data, this.toNum(res));
            }
            // 300
            const totalRow30 = this.formApp9[0].child.find(item => item.is_calculation === 'calc' && item.pkfo_3_code === '300');
            const mainRow1Value = parseFloat(totalRow10[data]) || 0;
            const mainRow2Value = parseFloat(totalRow20[data]) || 0;
            if (totalRow30 && totalRow20 && totalRow10) {
                this.$set(totalRow30, data, this.toNum(mainRow1Value - mainRow2Value));
            }
            // 400
            const totalRow40 = this.formApp9[1].child.find(item => item.is_calculation === 'calc' && item.pkfo_3_code === '400');
            const sumRows40 = this.formApp9[1].child.filter(item => ['310', '320', '330', '340', '350'].includes(item.pkfo_3_code));
            if (totalRow40 && sumRows40) {
                const res = sumRows40.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                this.$set(totalRow40, data, this.toNum(res));
            }
            // 410 - 460
            const mainRow41 = this.formApp9[1].child.find(item => item.is_calculation === 'calc' && item.pkfo_3_code === child.pkfo_3_code);
            const totalRows41 = this.formApp9[1].child.filter(item => item.is_calculation !== 'calc' && item.pkfo_3_code === child.pkfo_3_code);
            if (mainRow41 && totalRows41) {
                const res = totalRows41.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                this.$set(mainRow41, data, this.toNum(res));
            }
            // 500
            const totalRow50 = this.formApp9[1].child.find(item => item.is_calculation === 'calc' && item.pkfo_3_code === '500');
            const sumRows50 = this.formApp9[1].child.filter(item => {
                const code = Number(item.pkfo_3_code);
                return code >= 410 && code <= 460 && item.is_calculation === 'calc';
            });
            if (totalRow50 && sumRows50) {
                const res = sumRows50.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                this.$set(totalRow50, data, this.toNum(res));
            }
            // 600
            const totalRow60 = this.formApp9[1].child.find(item => item.is_calculation === 'calc' && item.pkfo_3_code === '600');
            const mainRow4Value = parseFloat(totalRow40[data]) || 0;
            const mainRow5Value = parseFloat(totalRow50[data]) || 0;
            if (totalRow60 && totalRow40 && totalRow50) {
                this.$set(totalRow60, data, this.toNum(mainRow4Value - mainRow5Value));
            }
            // 700
            const totalRow70 = this.formApp9[2].child.find(item => item.is_calculation === 'calc' && item.pkfo_3_code === '700');
            const sumRows70 = this.formApp9[2].child.filter(item => {
                const code = Number(item.pkfo_3_code);
                return code >= 610 && code <= 620;
            });
            if (totalRow70 && sumRows70) {
                const res = sumRows70.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                this.$set(totalRow70, data, this.toNum(res));
            }
            // 710 - 720
            const mainRow71 = this.formApp9[2].child.find(item => item.is_calculation === 'calc' && item.pkfo_3_code === child.pkfo_3_code);
            const totalRows71 = this.formApp9[2].child.filter(item => item.is_calculation !== 'calc' && item.pkfo_3_code === child.pkfo_3_code);
            if (mainRow71 && totalRows71) {
                const res = totalRows71.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                this.$set(mainRow71, data, this.toNum(res));
            }
            // 800
            const totalRow80 = this.formApp9[2].child.find(item => item.is_calculation === 'calc' && item.pkfo_3_code === '800');
            const sumRows80 = this.formApp9[2].child.filter(item => {
                const code = Number(item.pkfo_3_code);
                return code >= 710 && code <= 720 && item.is_calculation === 'calc';
            });
            if (totalRow80 && sumRows80) {
                const res = sumRows80.reduce((acc, row) => acc + (parseFloat(row[data]) || 0), 0);
                this.$set(totalRow80, data, this.toNum(res));
            }
            // 900
            const totalRow90 = this.formApp9[2].child.find(item => item.is_calculation === 'calc' && item.pkfo_3_code === '900');
            const mainRow7Value = parseFloat(totalRow70[data]) || 0;
            const mainRow8Value = parseFloat(totalRow80[data]) || 0;
            if (totalRow90 && totalRow70 && totalRow80) {
                this.$set(totalRow90, data, this.toNum(mainRow7Value - mainRow8Value));
            }
            // 910
            const totalRow91 = this.formApp9[2].child.find(item => item.is_calculation === 'calc' && item.pkfo_3_code === '910');
            const mainRow3Value = parseFloat(totalRow30[data]) || 0;
            const mainRow6Value = parseFloat(totalRow60[data]) || 0;
            const mainRow9Value = parseFloat(totalRow90[data]) || 0;
            if (totalRow91 && totalRow30 && totalRow60 && totalRow90) {
                this.$set(totalRow91, data, this.toNum(mainRow3Value + mainRow6Value + mainRow9Value));
            }

        },
        async LoadApp9() {
            this.loading = true;
            try {
                const response = await fetch(`/api-py/get_dict_items_appendix9/` + this.year + '/' + this.version + '/' + this.abp + '/' + this.obl + '/' + this.region);
                if (response.status === 200) {
                    const data = await response.json();
                    this.formApp9 = data.result;
                    this.inputValues = data.input;
                    this.repdate = data.repdate;
                    const keysToBlur = ['value', 'forecast_1', 'forecast_2', 'forecast_3'];

                    const applyBlur = (row) => {
                        keysToBlur.forEach(key => {
                            if (key in row) {
                                this.blurInput(row, key, true);
                            }
                        });
                    };

                    this.formApp9.forEach(parent => {
                        if (parent.child && Array.isArray(parent.child)) {
                            parent.child.forEach(applyBlur);
                        }
                    });
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка Приложения 8', error.toString());
            }
            this.loading = false;
        },
        async SaveData() {
            if (Object.keys(this.inputValues).length > 0) {
                this.inputData = [];
                const item = Object.assign({});

                this.$set(item, 'inputValues', this.inputValues);
                this.$set(item, 'cur_year', this.year);
                this.$set(item, 'budget_variant', this.version);
                this.$set(item, 'abp_code', this.abp);
                this.$set(item, 'user_id', this.$store.getters.user_uuid);
                this.$set(item, 'update_date', moment().format('YYYY-MM-DD'));
                this.$set(item, 'code_form', 'PR9');
                this.$set(item, 'region_code', this.region);
                this.$set(item, 'data_type', this.dataType);
                this.isLoad = true;
                this.inputData.push(item);
                try {
                    const response = await fetch('/api-py/add_data_to_appendix9', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(this.inputData)
                    });
                    const result = await response.json();
                    if (response.status === 200 && result.result === 'success') {
                        this.isSave = false;
                        await this.LoadApp9();
                        this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    } else {
                        if (result.result === 'error') {
                            throw 'Ошибка сохранения данных.';
                        }
                    }
                } catch (e) {
                    this.makeToast('danger', 'Предупреждение', e.toString());
                } finally {
                    this.isLoad = false;
                }
            }

        },
        downloadRep() {
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.isLoad = true;
                this.curParams = { year: this.year, region: this.region, version: this.version, abp: this.abp, data_type: this.dataType, reg_name: this.region_name, ver_name: this.version_name, obl: this.obl };
                Ax(
                    {
                        url: '/api-py/forecast_reporting_download_app9/' + JSON.stringify(this.curParams),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Приложение 9.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
            } finally {
                this.isLoad = false
            }
        },
        toggleRow(index) {
            this.$set(this.openRows, index, !this.openRows[index]);
        },
        toNum(value, fix = 1) {
            if (typeof (value) === 'string') {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof (value) === 'number') {
                return parseFloat(value.toFixed(fix));
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if (typeof (value) === 'undefined') {
                return 0;
            }
            if (isNaN(value)) {
                return 0;
            }
            if (isFinite(value)) {
                return 0;
            }
            if (value === null) {
                return 0;
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
    }
}
</script>